import React, { useState, useEffect } from "react";
import logo from "assets/images/logo.png";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import {
    FiChevronDown,
    FiChevronUp,
    FiChevronRight,
    FiArrowLeft,
} from "react-icons/fi";
import { useNavigate, Link } from "react-router-dom";
import { sublinks, sublinksCopy } from "./constants";
import TopBar from "./TopBar";

interface Props {
    className?: string;
    isBgWhite?: boolean;
    signInLink?: string;
    signUpLink?: string;
    signInFn?: any;
    signUpFn?: any;
    showLogIn?: boolean;
    signUpLabel?: string;
    removeReferral?: boolean;
}
let mysubs = sublinks as any;
let links = ["Solutions"];
let subcopies = sublinksCopy as any;

export default function Header({
    signInLink,
    signUpLink,
    isBgWhite,
    className,
    signInFn,
    signUpFn,
    showLogIn,
    signUpLabel,
    removeReferral,
}: Props) {
    const navigation = useNavigate();
    const [showMenu, setMenu] = useState(false);
    const [display, setDisplay] = useState("");
    const toggleMenu = () => {
        setMenu((prev) => !prev);
    };

    const disableScroll = () => {
        document.body.style.overflow = "hidden";
    };

    const enableScroll = () => {
        document.body.style.overflow = "auto";
    };

    useEffect(() => {
        showMenu ? disableScroll() : enableScroll();
    }, [showMenu]);

    return (
        <>
            <TopBar
                display={display}
                setDisplay={setDisplay}
                className={" bg-white z-30"}
                signInLink={signInLink}
                signUpLink={signUpLink}
                showMenu={showMenu}
                toggleMenu={toggleMenu}
                links={links}
                signInFn={signInFn}
                signUpFn={signUpFn}
                showLogIn={showLogIn}
                signUpLabel={signUpLabel}
                removeReferral={removeReferral}
            />

            {showMenu && (
                <div
                    onClick={() => setMenu(!showMenu)}
                    className={`w-full h-full lg:hidden z-[99] fixed top-0 bg-white text-bblack-1 flex flex-col items-center  left-0 p-auto text-lg`}
                >
                    <div
                        className={
                            "flex py-5 px-4 w-full hover:cursor-pointer self-center  md:px-12 items-center  justify-between z-30 " +
                            className
                        }
                    >
                        {" "}
                        <div className='2xl:m-auto max-w-[1234px] w-full flex justify-between  items-center'>
                            <div className='flex items-center '>
                                <img
                                    src={logo}
                                    alt='bnkle'
                                    className='md:mr-12'
                                    onClick={() => navigation("/")}
                                />
                                <div className=' hidden lg:flex items-center space-x-6 mt-5'>
                                    {links.map((m, i) => (
                                        <span
                                            onClick={() => {
                                                if (i === 0 || i === 1) {
                                                    setDisplay(m);
                                                }
                                            }}
                                            className='hidden lg:flex items-center text-headerblue'
                                            key={i}
                                        >
                                            {m}
                                            {(i === 0 || i === 1) && (
                                                <span className='ml-1'>
                                                    {display === links[i] ? (
                                                        <FiChevronUp
                                                            size={16}
                                                            color={"headerblue"}
                                                        />
                                                    ) : (
                                                        <FiChevronDown
                                                            size={16}
                                                            color={"headerblue"}
                                                        />
                                                    )}
                                                </span>
                                            )}
                                        </span>
                                    ))}
                                    {!removeReferral && (
                                        <span
                                            onClick={() => {
                                                navigation("/referral");
                                            }}
                                            className='text-headerblue'
                                        >
                                            Referral Program
                                        </span>
                                    )}
                                </div>
                            </div>

                            <div className='lg:flex space-x-6 hidden items-center text-lg mt-5'>
                                {showLogIn === undefined ||
                                showLogIn === true ? (
                                    <a
                                        onClick={() => {
                                            if (signInFn) {
                                                signInFn();
                                            }
                                        }}
                                        href={signInLink}
                                        className='text-headerblue'
                                        rel='no-referer'
                                    >
                                        Login
                                    </a>
                                ) : null}
                                <a
                                    onClick={() => {
                                        if (signUpFn) {
                                            signUpFn();
                                        }
                                    }}
                                    href={signUpLink}
                                >
                                    <button className='py-2 rounded-md bg-bblue px-8 text-white'>
                                        {signUpLabel ? signUpLabel : "Sign up"}
                                    </button>
                                </a>
                            </div>
                            <span className='z-30 lg:hidden'>
                                {!showMenu ? (
                                    <AiOutlineMenu
                                        size={16}
                                        className='text-3xl ml-4 lg:hidden z-30'
                                        onClick={toggleMenu}
                                        color={"headerblue"}
                                    />
                                ) : (
                                    <AiOutlineClose
                                        size={16}
                                        className='text-xl ml-4 lg:hidden z-30'
                                        onClick={toggleMenu}
                                        color={"#222B34"}
                                    />
                                )}
                            </span>
                        </div>
                    </div>
                    <span
                        className=' flex flex-col mt-10 px-4 md:p-2 w-full md:px-12  mb-10  '
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        {links.map((m, i) => (
                            <span
                                onClick={() => {
                                    if (display === m) {
                                        setDisplay("");
                                    } else {
                                        setDisplay(m);
                                    }
                                }}
                                className='flex flex-col w-full cursor-pointer  '
                            >
                                <span
                                    className={`text-bblack-1 flex justify-between items-center w-full pb-8 font-SatoshiMedium`}
                                    key={i}
                                >
                                    {m}
                                    {(i === 0 || i === 1) && (
                                        <span className='ml-1'>
                                            <FiChevronRight />
                                        </span>
                                    )}
                                </span>
                                {display === links[i] && mysubs[display] && (
                                    <div className='absolute top-0 left-0 w-full h-screen bg-white z-[999] pt-4 px-4 md:px-12'>
                                        <button onClick={() => setDisplay("")}>
                                            <FiArrowLeft />
                                        </button>
                                        <div className='pt-8 flex flex-col gap-6'>
                                            {mysubs[display].map((m: any) => (
                                                <span className='flex justify-between items-center w-full'>
                                                    <a
                                                        className='text-bblack-1'
                                                        href={m.link}
                                                        target={
                                                            m.link.includes(
                                                                "http"
                                                            )
                                                                ? "_blank"
                                                                : ""
                                                        }
                                                    >
                                                        {m.label}
                                                    </a>
                                                    <FiChevronRight />
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </span>
                        ))}
                        <div className='flex flex-col gap-8 font-SatoshiMedium pb-8'>
                            <span className='flex justify-between items-center w-full'>
                                <a
                                    href={`https://calculator.${process.env.REACT_APP_DOMAIN}`}
                                    target='_blank'
                                >
                                    Cost Calculator
                                </a>
                                <FiChevronRight />
                            </span>
                            {!removeReferral && (
                                <div
                                    onClick={() => {
                                        navigation("/referral");
                                    }}
                                    className={`flex justify-between items-center w-full cursor-pointer`}
                                >
                                    <span>Refer-to-earn</span>
                                    <FiChevronRight />
                                </div>
                            )}
                            <span className='flex justify-between items-center w-full'>
                                <a href='' className='text-headerblue'>
                                    Pricing
                                </a>
                                <FiChevronRight />
                            </span>
                            <span className='flex justify-between items-center w-full'>
                                <Link to='/contact'>Contact us</Link>
                                <FiChevronRight />
                            </span>
                        </div>
                        {showLogIn === undefined || showLogIn === true ? (
                            <a
                                onClick={() => {
                                    if (signInFn) {
                                        signInFn();
                                    }
                                }}
                                href={signInLink}
                                className={` py-2 rounded-md my-3 px-8 w-full border-ashShade-12 border text-center text-bblack-1 font-SatoshiMedium hover:bg-ashShade-13 active:bg-ashShade-14"`}
                                rel='no-referer'
                            >
                                Login
                            </a>
                        ) : null}
                        <a
                            onClick={() => {
                                if (signUpFn) {
                                    signUpFn();
                                }
                            }}
                            href={signUpLink}
                        >
                            <button className='py-2 rounded-md bg-bblue px-8 w-full text-center text-white'>
                                {signUpLabel ? signUpLabel : "Sign up"}
                            </button>
                        </a>
                    </span>
                </div>
            )}
            {display && (
                <>
                    <div
                        onClick={() => {
                            setDisplay("");
                        }}
                        className=' w-screen hidden   bg-black  h-full   bg-opacity-70 lg:flex lg:flex-col jusitify-center z-[90] fixed top-0  left-0'
                    >
                        <div className='top-0 '>
                            {
                                <div className='w-full'>
                                    <div
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                        className='w-full z-[999] bg-white  '
                                    >
                                        <TopBar
                                            display={display}
                                            setDisplay={setDisplay}
                                            className={className + " z-[999]"}
                                            signInLink={signInLink}
                                            signUpLink={signUpLink}
                                            showMenu={showMenu}
                                            toggleMenu={toggleMenu}
                                            links={links}
                                            signInFn={signInFn}
                                            signUpFn={signUpFn}
                                            removeReferral={removeReferral}
                                        />
                                    </div>
                                </div>
                            }
                            <div className='w-full  '>
                                <div className='w-[1234px] mx-auto'>
                                    <div className='bg-white rounded-lg h-fit w-fit cursor-pointer  p-10 ml-40  mt-2  '>
                                        <p className='text-bash mb-8'>
                                            {display}
                                        </p>
                                        <div
                                            className={`grid ${
                                                mysubs[display] &&
                                                mysubs[display].length > 2
                                                    ? "grid-cols-3"
                                                    : "grid-cols-2"
                                            } gap-x-16  gap-y-4`}
                                        >
                                            {mysubs[display] &&
                                                mysubs[display].map(
                                                    (m: any) => (
                                                        <a
                                                            href={m.link}
                                                            target={
                                                                m.link.includes(
                                                                    "http"
                                                                )
                                                                    ? "_blank"
                                                                    : ""
                                                            }
                                                        >
                                                            <span className='flex hover:bg-lightblue rounded-md px-4 py-2 flex-col '>
                                                                <span className='flex  items-center mb-2 text-black '>
                                                                    <span
                                                                        className={`rounded-md p-2 font-Bold ${
                                                                            m.iconbg
                                                                                ? m.iconbg
                                                                                : " bg-gray-50"
                                                                        } mr-2`}
                                                                    >
                                                                        {React.createElement(
                                                                            m.icon,
                                                                            {
                                                                                size: 16,
                                                                                color: m.iconcolor,
                                                                            }
                                                                        )}
                                                                    </span>
                                                                    {m.label}
                                                                </span>
                                                                <p className='text-bash max-w-[284px]'>
                                                                    {
                                                                        subcopies[
                                                                            m
                                                                                .link
                                                                        ]
                                                                    }
                                                                </p>
                                                            </span>
                                                        </a>
                                                    )
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
