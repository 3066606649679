import image3 from "assets/image3.svg";
import image41 from "assets/image41.svg";
import image15 from "assets/image15.svg";
import image14 from "assets/image14.svg";
import image13 from "assets/image13.svg";

let questions = [
  //How do I ensure my money is safe?
  {
    question: "How Long will the process take?",
    answer: "You can sign up and create a project on bnkle within 7 minutes.",
  },
  {
    question: "How many contractors can I choose from?",
    answer:
      "If you select our Standard and Enterprise Plans, you will have access to the entire pool of contractors on the platform. For the Premium Plan, your dedicated project manager will provide a shortlist of five verified contractors that fit your project for your review.",
  },
  {
    question: "How do I ensure my money is safe?",
    answer:
      "You may require that contractors provide Insurance Bonds or Advanced Payment Guarantees to protect you from any loss.",
  },
  {
    question: "Are there legal documents to be signed?",
    answer:
      "Yes, you wll be required to sign contract documents if you award or are awarded a contract on the platform.",
  },

  {
    question: "How can Bnklé help me manage my construction project?",
    answer:
      "Bnklé provides a construction management software tool that helps construction professionals manage and organize their projects, including tasks such as budgeting, scheduling, and document management.",
  },
  {
    question:
      "What types of features does Bnklé construction management software application offer?",
    answer:
      "The range of features for construction management includes project scheduling, budgeting, document management, and communication tools.\n In the future, we will offer features such as time tracking, resource allocation, and quality control.",
  },
  {
    question: "Can I use Bnklé on any device?",
    answer:
      "Bnklé is a web-based or cloud-based tools, which means they can be accessed from any device with an internet connection. Native mobile apps for iOS and Android devices are coming soon.",
  },
  {
    question: "How does Bnklé help with budgeting?",
    answer:
      "Bnklé helps you with budgeting by providing tools to track your costs and expenses, as well as generate reports to help you identify any potential cost overruns. This ensures that your projects stay on budget and that any potential issues can be addressed before they become major problems.",
  },
  {
    question: "Can Bnklé help me with scheduling?",
    answer:
      "Yes, Bnklé helps you with scheduling by providing you tools to create and track project schedules, as well as assign tasks to team members. This can help you ensure that projects stay on track and that all team members are aware of their responsibilities.",
  },
  {
    question: "Does Bnklé help with document management?",
    answer:
      "Bnklé helps with document management by providing a central repository for all project-related documents. This can include contracts, plans, drawings, and other important documents, and can help ensure that all team members have access to the most up-to-date versions of these documents.",
  },
];

const refFaqs = [
  {
    question: "Get and Share your referral link",
    answer:
      "Get your referral link and share with your friends through email or through social media.",
  },
  {
    question: "Track Your Referrals",
    answer:
      "Track how your referrals are doing on your dashboard, including those that have converted.",
  },
  {
    question: "Earn $300 when your friends start a project on Bnkle",
    answer:
      "As a thank you, when any of your friends start a project on Bnkle, you earn $300. The more friends who join and take action, the more you earn.",
  },
  {
    question: "Everyone Wins 🏆",
    answer:
      "Every friend that you invite to build with Bnkle will get free switches and sockets with life time warranty.",
  },
  {
    question: "Redeem your earnings",
    answer: "You can Redeem your earnings at anytime.",
  },
  {
    question: "Spread the love keep earning",
    answer:
      "There's no limit to the number of friends you can refer or the rewards you can earn. Keep spreading the word, and keep enjoying the benefits of our Referral Program.",
  },
];

const copies = [
  {
    image: image3,
    title: "Choose from Verified  Contractors and Consultants",
    subtitle:
      "Access our curated pool of vetted contractors and consultants. Our rating system ensures that you find the perfect match for your project requirements.",
  },
  {
    image: image14,
    title: "Receive and Evaluate Bids Online",
    subtitle:
      "Conduct competitive bids online to give you best value for money. Automate the tender process from issuing invitations to bid, to receiving and analyzing bids.",
  },
  {
    image: image41,
    title: "Gain clarity and control of your Project",
    subtitle:
      "Our cloud based repository of project documents and decisions ensures that you can recall and review every detail of your project real time.",
  },
  {
    image: image15,
    title: "Eliminate the risk of financial loss.",
    subtitle:
      "Contractors provide insurance cover for any funds you disburse for the construction of your project.",
  },
  {
    image: image13,
    title: "Real time project tracking",
    subtitle:
      "Gain complete visibility of your project. See real-time project tracking and catch issues before they become problems.",
  },
];

export { questions, copies, refFaqs };
