import React, { useEffect, useState } from "react";
import house from "assets/dashboards.svg";
import backgroundstripes from "assets/stripes3.png";
import Button from "components/shared/Button";
import { AiOutlineArrowRight } from "react-icons/ai";
import { reasons } from "./constants";
import Fotter from "../../components/shared/Fotter";
import NewHeader from "components/shared/NewHeader";
import { useFormik } from "formik";
import * as Yup from "yup";
//import { toast } from "react-toast";
import axios from "axios";
import { IoClose } from "react-icons/io5";
import toast from "react-simple-toasts";

type data = {
  businessName: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

let schema = Yup.object({
  businessName: Yup.string()
    .min(2, "business name cannot be less than two characters")
    .required("please input business name"),
  firstName: Yup.string()
    .min(2, "firstname cannot be less than two characters")
    .required("please input firstname"),
  lastName: Yup.string()
    .min(2, "lastname cannot be less than two characters")
    .required("please input lastname"),
  phone: Yup.string()
    .min(9, "phone cannot be less than nine characters")
    .required("please input lastname"),
  email: Yup.string()
    .email("please enter a valid email")
    .required("please input email"),
});
let url = "https://sheet2api.com/v1/NDjDSZa8QwW4/bnklecrm/Enterprise";

let Modal = ({ closer }: { closer?: any }) => {
  const [submitting, setSubmitting] = useState(false);

  const submitForm = async (data: data) => {
    setSubmitting(true);
    try {
      // let res = await axios.post(url, {
      //   ...data,
      //   timestamp: new Date().toISOString(),
      // });
      let _data: any = {
        ...data,
      };


      let resp = await axios.post(
        `${process.env.REACT_APP_IAM_URL}/leads/request`,
        {
          ..._data,
        }
      );

      console.log(resp);

      if (resp.status < 300) {
        toast(
          "Thank you for booking a demo with the sales team. We will reach out soon",
          {
            className: "text-white  z-[99999]",
            time: 4000,
          }
        );
        formik.resetForm();
        closer();
      } else {
        toast("We did not get your booking. Please try again", {
          className: "text-white  z-[99999]",
          time: 4000,
        });
      }
    } catch (error) {
      toast("We did not get your booking. Please try again", {
        className: "text-white  z-[99999]",
        time: 4000,
      });
    }
    setSubmitting(false);
  };
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      businessName: "",
      phone: "",
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      await submitForm(values);
    },
  });
  return (
    <div className="m-auto ">
      <p className=" w-full flex justify-end">
        {" "}
        <span
          onClick={() => closer()}
          className="rounded-full p-2 hover:shadow-lg  "
        >
          <IoClose size={24} color="black" />
        </span>
      </p>
      <p className="text-2xl text-center mb-8 font-DemiBold">
        Get started with Bnklé
      </p>
      <label
        className="text-bblack-0 mb-6 mt-2 flex flex-col "
        htmlFor="businessName"
      >
        Business Name
        <input
          className="w-full py-3 px-4  mt-2 outline-bblue border-bblack-0 border  rounded-md bg-white text-bblack-0"
          type="text"
          name="businessName"
          placeholder="ABC Inc."
          value={formik.values.businessName}
          onChange={formik.handleChange}
        />
        {formik.errors.businessName && formik.touched.businessName && (
          <p className="text-red text-sm text-red-500">
            {formik.errors.businessName}
          </p>
        )}
      </label>
      <div className="w-full flex flex-col md:flex-row justify-between gap-y-3 lg:gap-y-0 ">
        <label
          className="text-bblack-0 mt-2 mr-3 flex-1 flex flex-col"
          htmlFor="firstName"
        >
          First Name
          <input
            className="w-full py-3 px-4 mt-2  outline-bblue border-bblack-0 border  rounded-md bg-white text-bblack-0"
            type="text"
            name="firstName"
            placeholder="First name"
            value={formik.values.firstName}
            onChange={formik.handleChange}
          />
          {formik.errors.firstName && formik.touched.firstName && (
            <p className="text-red text-sm text-red-500">
              {formik.errors.firstName}
            </p>
          )}
        </label>
        <label
          className="text-bblack-0 mb-6 mt-2 flex flex-1 flex-col"
          htmlFor="lastName"
        >
          Last Name
          <input
            className="w-full py-3 px-4 mt-2 outline-bblue border-bblack-0 border  rounded-md bg-white text-bblack-0"
            type="text"
            name="lastName"
            placeholder="Last name"
            value={formik.values.lastName}
            onChange={formik.handleChange}
          />
          {formik.errors.lastName && formik.touched.lastName && (
            <p className="text-red text-sm text-red-500">
              {formik.errors.lastName}
            </p>
          )}
        </label>
      </div>
      <label className="text-bblack-0 mb-6 mt-2 flex flex-col " htmlFor="email">
        Email
        <input
          className="w-full py-3 px-4  mt-2 outline-bblue border-bblack-0 border  rounded-md bg-white text-bblack-0"
          type="text"
          name="email"
          placeholder="you@company.com"
          value={formik.values.email}
          onChange={formik.handleChange}
        />
        {formik.errors.email && formik.touched.email && (
          <p className="text-red text-sm text-red-500">{formik.errors.email}</p>
        )}
      </label>
      <label className="text-bblack-0 mb-6 mt-2 flex flex-col " htmlFor="phone">
        Phone Number
        <input
          className="w-full py-3 px-4  mt-2 outline-bblue border-bblack-0 border  rounded-md bg-white text-bblack-0"
          type="tel"
          name="phone"
          placeholder="+234"
          value={formik.values.phone}
          onChange={formik.handleChange}
        />
        {formik.errors.phone && formik.touched.phone && (
          <p className="text-red text-sm text-red-500">{formik.errors.phone}</p>
        )}
      </label>
      <button
        onClick={(e) => formik.handleSubmit()}
        className="bg-bblue text-white py-3 mt-6 flex justify-center  px-8 rounded-md w-full "
      >
        {submitting ? (
          <div className="w-6 h-6 border-4  rounded-full border-t-white animate-spin border-x-slate-50 border-b-transparent   "></div>
        ) : (
          "Book a demo"
        )}
      </button>
    </div>
  );
};

export default function Enterprise() {
  useEffect(() => {
    document.title = "Enterprise";
  }, []);
  const [modal, setModal] = useState(false);

  const Reasons = React.Children.toArray(
    reasons.map((one, index) => (
      <div
        className={`w-full xl:w-[calc(100vw-((100vw-1234px)/2))] lg:justify-between flex items-center flex-col  ${
          index % 2 ? "lg:flex-row-reverse ml-0" : "lg:flex-row mr-0"
        } mt-12 max-w-9xl mx-auto h-fit text-black`}
      >
        <section className="w-full xl:max-w-[380px] lg:justify-start xl:p-0 lg:w-auto p-5 md:px-20  flex flex-col md:justify-center">
          <h5 className="font-DemiBold text-center lg:text-left text-xl md:text-2xl lg:text-3xl">
            {one.question}
          </h5>
          <p className="text-sm text-bash text-center lg:text-left my-4">
            {one.answer}
          </p>
          <button className="flex items-center justify-center lg:justify-start text-borange font-Medium">
            <span>Get started</span>
            <AiOutlineArrowRight className="text-sm ml-2" />
          </button>
        </section>
        <img
          alt=""
          src={one.image.mobile}
          className="w-9/12 lg:w-1/2 lg:hidden"
        />
        <img
          alt=""
          src={one.image.desktop}
          className="w-9/12 lg:w-1/2 hidden lg:block"
        />
      </div>
    ))
  );

  return (
    <div className=" relative flex flex-col items-center justify-start ">
      <div className="w-full flex relative items-center flex-col h-full bg-ashShade-10">
        <NewHeader
          className="relative z-10"
          signInFn={() => setModal(true)}
          signUpFn={() => setModal(true)}
          signUpLabel="Contact Sales"
          showLogIn={false}
        />
        {modal && (
          <div
            onClick={() => {
              setModal(!modal);
            }}
            className="fixed   w-screen z-[999] h-screen top-0 left-0 flex items-center justify-center bg-black bg-opacity-60"
          >
            <div
              onClick={(e) => e.stopPropagation()}
              className=" max-w-[432px] p-4 lg:p-6 rounded-md bg-white w-4/5 md:w-2/3 lg:w-1/2 "
            >
              <Modal closer={() => setModal(false)} />
            </div>
          </div>
        )}
        <div className="w-full items-center ">
          <div className="px-2 md:px-0 mt-5 md:mt-20 relative z-10 mb-10 flex flex-col mx-auto max-w-[1102px] text-center">
            <span className="font-Bold text-3xl md:text-7xl text-white">
              Manage your building
              <br />
              projects like a pro.
            </span>
            <span className=" text-pbg mt-4 self-center text-lg md:text-xl max-w-[600px]">
              Get all the tools and resources you need to manage multiple
              projects and project teams all in one place.
            </span>
            <div className="mt-8 mx-auto">
              <Button
                label="Contact sales"
                onClick={() => {
                  setModal(!modal);
                }}
              />
            </div>
          </div>
          <div className=" w-full">
            <img
              src={house}
              alt=""
              className="w-11/12 md:w-9/12 relative z-10 mx-auto max-w-[1234px]"
            />
            <div className=" absolute left-0 top-0 w-full  z-0">
              <img
                src={backgroundstripes}
                alt=""
                className="w-full object-cover h-full md:h-auto  object-bottom"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white z-20 pt-10 w-full">
        <div className=" bg-white flex mt-20 md:mt-28 flex-col text-black relative">
          <div className="mx-auto mb-3 text-base md:text-xl font-DemiBold text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-blue-900">
            Features
          </div>
          <div className="mx-3 md:mx-auto mb-3 text-3xl text-center md:text-4xl font-DemiBold">
            Here is why you should use bnkle
          </div>
          <div className="mx-4 md:mx-auto max-w-[843px] text-base md:text-xl text-justify md:text-center text-bash">
            Bnklé ensures that your construction process is seamless and
            engaging with its state of the arts, new and improved range of
            features
          </div>
        </div>

        <div className="bg-white w-full">{Reasons}</div>
      </div>
      <Fotter />
    </div>
  );
}
