import React from "react";
import hero from "assets/image4881.svg";
import Button from "../../components/shared/Button";
import Fotter from "../../components/shared/Fotter";
import stripes from "assets/stripes.png";
import Carousel from "../../components/shared/Carousel";
import NewHeader from "components/shared/NewHeader";
import { copiesForContractor } from "./constants";

interface Prop {
  isConsultant?: boolean;
}
const Contrator = ({ isConsultant }: Prop) => {
  React.useEffect(() => {
    document.title = "Contractor";
  }, []);

  return (
    <div className=" relative flex flex-col items-center justify-start ">
      <div className="w-full flex relative items-center flex-col h-full">
        <NewHeader
          isBgWhite
          signInLink={`https://auth.${process.env.REACT_APP_DOMAIN}/signin`}
          signUpLink={`https://auth.${process.env.REACT_APP_DOMAIN}`}
          signUpLabel="Create free account"
        />
        <div className="w-full ">
          <div className="relative xl:my-14 pl-3 lg:pl-0  flex flex-col xl:flex-row justify-between  px-2 md:px-0 mt-5 w-full">
            <div className=" lg:max-w-[calc(100vw-((100vw-1234px)/2))] xl:w-[calc(100vw-((100vw-1234px)/2))] flex flex-col lg:flex-row lg:justify-between items-center  mx-auto   lg:mr-0">
              <div className="flex flex-col  items-start  justify-start max-w-2xl relative z-10 md:my-12 xl:mr-0">
                <span className="font-Bold  xl:w-full text-4xl lg:w-full md:text-5xl xl:text-7xl text-center lg:text-left">
                  Gain <span className="text-bblue">access</span> to bid on a
                  steady flow of{" "}
                  <span className="text-bblue">construction projects</span>
                </span>
                <span className="text-bash max-w-[80%] mt-5 text-base md:text-xl self-center lg:self-start  text-center lg:text-left">
                  Sign up and get your company's profile in front of hundreds of
                  project owners. For free.
                </span>
                <div className="mx-auto self-start lg:mx-0 mt-8 w-fit">
                  <Button
                    label="Create Profile"
                    className="md:w-auto w-full"
                    onClick={() =>
                      window.location.assign(
                        `https://auth.${process.env.REACT_APP_DOMAIN}`
                      )
                    }
                  />
                </div>
              </div>
              <img
                alt=""
                src={hero}
                className="object-contain my-10 md:my-0 md:w-9/12 lg:w-1/2 xl:w-2/5 relative z-10"
              />
            </div>
            <img
              src={stripes}
              alt=""
              className="absolute w-full  object-cover -top-0 left-0"
            />
          </div>
          <Carousel
            copies={copiesForContractor}
            isprofessional
            isConsultant={isConsultant}
            link={`https://auth.${process.env.REACT_APP_DOMAIN}`}
          />
          <div className="my-10" />
          <Fotter />
        </div>
      </div>
    </div>
  );
};

export default Contrator;
