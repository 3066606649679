import React, { useContext, useRef, useState } from "react";
import { AuthContext } from "context";
import bnklelogo from "../../assets/bnklelogo.svg";
import { useNavigate } from "react-router-dom";
const Index = () => {
  let [auth, setAuth] = useContext(AuthContext);
  let navigate = useNavigate();
  let emailRef = useRef<any>();
  let passwordRef = useRef<any>();
  let [error, setError] = useState("");
  return (
    <div className="w-screen h-screen   pt-10">
      <img src={bnklelogo} alt="" className="w-20 mt-2 mx-auto" />
      <div className="w-1/3 p-5 border flex flex-col justify-center mt-3 items-center mx-auto">
        <input
          className="w-full  px-3 py-2 rounded-md  border mt-10 border-bblue"
          ref={emailRef}
          type="text"
          placeholder="Enter email"
        />
        <input
          className="border mt-2  rounded-md  border-bblue w-full px-3 py-2  "
          type="text"
          ref={passwordRef}
          placeholder="Enter Password"
        />
        <span className="text-red text-sm">{error}</span>
        <button
          className="bg-bblue my-2  rounded-md  text-white px-3 py-2 "
          onClick={() => {
            setError("");
            if (
              emailRef?.current?.value == process.env.REACT_APP_EMAIL &&
              passwordRef?.current?.value === process.env.REACT_APP_PASSWORD
            ) {
              setAuth(true);
              navigate("/");
              return;
            }
            setAuth(false);
            setError("email or password is wrong");
            return;
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default Index;
