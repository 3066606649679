import nigeria from "assets/nigeria.svg";
import canada from "assets/canada.svg";

const addresses = [
  {
    text: "Innovation Place,116 Research Drive Saskatoon,Saskatchewan",
    image: canada,
  },
  {
    text: "The Epicentre,1 Zilly Aggrey Drive,Karmo Abuja",
    image: nigeria,
  },
];

export default addresses;
