import React from "react";
import backgroundstripes2 from "assets/stripes2.png";
import { tabsforContractors, tabsforUsers } from "pages/contractor/constants";
interface Props {
  copies: { title: string; subtitle: string; image: string }[];
  hasTopOffSet?: boolean;
  isConsultant?: boolean;
  isprofessional?: boolean;
  link?: string;
}
export default function Carousel({
  hasTopOffSet,
  copies,
  isConsultant,
  isprofessional,
  link,
}: Props) {
  const [copy, setCopy] = React.useState(0);
  let tabs = isprofessional ? tabsforContractors : tabsforUsers;
  return (
    <div
      className={`w-full overflow-hidden px-2 lg:px-0 flex pt-5 md:pt-20 xl:pt-28 flex-col text-white  bg-ashShade-10 relative ${
        hasTopOffSet && " -top-6 md:-top-56 "
      }`}
    >
      <div className="mx-auto mb-3 text-base font-DemiBold text-transparent bg-clip-text bg-gradient-to-r from-blue-300 to-bblue relative z-10">
        Features
      </div>
      <div className="mx-auto w-11/12 md:w-8/12 xl:w-auto mb-3 text-center text-2xl md:text-4xl font-DemiBold relative z-10">
        Here are some reasons to choose Bnkle
      </div>
      <img
        alt=""
        src={backgroundstripes2}
        className="absolute w-full h-full top-0 object-cover left-0 -z-0"
      />
      <div className="mx-auto max-w-[743px] hidden md:block text-xl text-center relative z-10">
        Bnkle ensures that your construction process is seamles and engaging
        with its state of the arts , new and improved range of features
      </div>
      <div className="flex pr-0 md:pr-0 lg:mt-10  mx-auto xl:ml-auto items-center cursor-pointer md:w-auto w-full inter overflow-x-auto no-scrollbar mt-5 md:mt-12 self-center md:gap-x-3 m-auto border-2 border-white lg:justify-center rounded-3xl relative z-10">
        {React.Children.toArray(
          tabs.map((one, index) => (
            <p
              onClick={() => setCopy(index)}
              className={`
                                cursor-pointer hover:opacity-80
                                py-2 xl:py-3 px-2 xl:px-4 whitespace-nowrap w-auto
                                ${copy === index && "rounded-3xl bg-bblue"}
                            `}
            >
              {one}
            </p>
          ))
        )}
      </div>
      <div className="lg:container mx-auto lg:mr-0 xl:w-[calc(100vw-((100vw-1234px)/2))]   flex md:flex-row flex-col-reverse pb-10 md:pb-32 items-center justify-between mt-10 md:mt-20 relative z-10">
        <div className="md:ml-10 md:mr-10 xl:mr-0 xl:ml-10 max-w-md md:max-w-[466px] flex flex-col mt-5 md:mt-10">
          <span className="text-xl md:text-3xl mb-4 xl:mb-5 font-DemiBold">
            {copies[copy].title}
          </span>
          <span className="text-base md:text-xl mb-5">
            {copies[copy].subtitle.includes("Contractors") && isConsultant
              ? copies[copy].subtitle.replaceAll("Contractors", "Consultants")
              : copies[copy].subtitle}
          </span>
          <span className="text-borange text-base font-Medium">
            Get started&nbsp;&nbsp; &#8594;
          </span>
        </div>
        <img
          src={copies[copy].image}
          alt=""
          className="relative md:left-0 left-20 w-full lg:transform lg:scale-125 2xl:scale-100 lg:translate-y-10 md:w-2/5"
        />
      </div>
    </div>
  );
}
//
