import image42 from "assets/image42.svg";
import image13 from "assets/image13.svg";
import image16 from "assets/image16.svg";
import image14 from "assets/image14.svg";
import image29 from "assets/image29.svg";
import contractor1 from "assets/contractor1.png";
import contractor2 from "assets/contractor2.png";
import contractor3 from "assets/contractor3.png";
import contractor4 from "assets/contractor4.png";
import contractor5 from "assets/contractor5.png";

let questions = [
  {
    question: "How Long will the process take?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  },
  {
    question: "How many contractors can I choose from?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  },
  {
    question: "How do I ensure my money is safe?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  },
  {
    question: "Are there legal documents to be signed?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  },
];

const copiesForContractor = [
  {
    image: contractor5,
    title: "Access all project information from one dashboard.",
    subtitle:
      "Get access to a steady flow of projects to bid for and submit your bids online in a transparent process. All for free.",
  },
  {
    image: contractor4,
    title:
      "Forget tender fees. Submit bids online for multiple projects with a few clicks.",
    subtitle:
      "Get access to a steady flow of projects to bid for and submit your bids online in a transparent process. All for free.",
  },
  {
    image: contractor1,
    title: "Get best prices for building materials from verified vendors.",
    subtitle:
      "Access the widest variety of building material inventory from vendors across different project locations.",
  },
  {
    image: contractor2,
    title:
      "Track work progress and budget using our cutting-edge planning tools.",
    subtitle:
      "See real-time project status and share status reports with your clients with a few clicks.",
  },
  {
    image: contractor3,
    title:
      "Get Verified and climb to the top of the list of companies that project owners consider first.",
    subtitle:
      "Go through our simple vetting process and receive our verification mark that project owners trust.",
  },
];
const copies = [
  {
    image: image29,
    title:
      "Create and share your company’s online portfolio in minutes. For free.s",
    subtitle:
      "Access our curated pool of vetted contractors and consultants. Our rating system ensures that you find the perfect match for your project requirements.",
  },
  {
    image: image14,
    title: "Enjoy a simplified and faster bidding process at zero cost.",
    subtitle:
      "Conduct transparent online bidding process: Automate the tender process from issuing invitations to bid, to receiving and analysing bids.",
  },
  {
    image: image16,
    title: "Gain access to bid on multiple high value projects.",
    subtitle:
      "Our cloud based repository of project documents and decisions ensures that you can recall and review every detail of your project real time.",
  },
  {
    image: image13,
    title: "Access construction management tools to better manage projects.",
    subtitle:
      "Contractors provide insurance to cover the funds you disburse for the constuction of your project.",
  },
  {
    image: image42,
    title: "Get your company verified to improve your reliability/status.",
    subtitle:
      "Get increased visibility on project progress and allows you to quickly identify any issues or challenges and adjust their strategies accordingly.",
  },
];
let tabsforContractors = [
  "One dashboard",
  "Zero tender fees",
  "Access vendors",
  "Real time tracking",
  "Get verified",
];

let tabsforUsers = [
  "Verified professionals",
  "Transparent bid process",
  "Project Clarity",
  "Secured Capital",
  "Real-time Tracking",
];

export { questions, copies, copiesForContractor,tabsforContractors,tabsforUsers };
