import lightlogo from "assets/bnklebetalight.svg";
import darklogo from "assets/bnklebetadark.svg";
import { useNavigate } from "react-router-dom";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

interface Prop {
  className?: string;
  isBgWhite?: boolean;
  signInLink?: string;
  signUpLink?: string;
  showMenu: boolean;
  toggleMenu: () => void;
  display: string;
  setDisplay: any;
  links: any;
  signUpFn?: any;
  signInFn?: any;
  showLogIn?: boolean;
  signUpLabel?: string;
  removeReferral?: boolean;
}

const TopBar = ({
  className,
  isBgWhite,
  signInLink,
  signUpLink,
  toggleMenu,
  showMenu,
  display,
  setDisplay,
  links,
  signInFn,
  signUpFn,
  showLogIn,
  signUpLabel,
  removeReferral,
}: Prop) => {
  let navigation = useNavigate();
  return (
    <div
      className={
        "flex py-5  m-auto  w-full hover:cursor-pointer self-center px-3 xl:px-0  items-center  justify-between  " +
        className
      }
    >
      {" "}
      <div className="2xl:m-auto m-auto max-w-[1234px] w-full flex justify-between  items-center">
        <div className="flex items-center ">
          <img
            src={isBgWhite ? lightlogo : darklogo}
            alt="bnkle"
            className="md:mr-12"
            onClick={() => navigation("/")}
          />
          <div className=" hidden lg:flex items-center space-x-6 mt-5">
            {links.map((m: any, i: number) => (
              <span
                onClick={() => {
                  if (i == 0 || i == 1) {
                    if (display == m) {
                      setDisplay("");
                    } else {
                      setDisplay(m);
                    }
                  }
                }}
                className={`${
                  isBgWhite ? "text-bblack-0" : "text-white"
                } hidden lg:flex items-center`}
                key={i}
              >
                {m}
                {(i === 0 || i === 1) && (
                  <span className="ml-1">
                    {display === links[i] ? (
                      <FiChevronUp
                        size={16}
                        color={isBgWhite ? "black" : "white"}
                      />
                    ) : (
                      <FiChevronDown
                        size={16}
                        color={isBgWhite ? "black" : "white"}
                      />
                    )}
                  </span>
                )}
              </span>
            ))}

            {!removeReferral && (
              <span
                onClick={() => {
                  navigation("/referral");
                }}
                className={`${isBgWhite ? "text-bblack-0" : "text-white"}`}
              >
                Referral Program
              </span>
            )}
          </div>
        </div>

        <div className="lg:flex space-x-6 hidden items-center text-lg mt-5">
          {showLogIn === undefined || showLogIn === true ? (
            <a
              onClick={() => {
                if (signInFn) {
                  signInFn();
                }
              }}
              href={signInLink}
              className={`${isBgWhite ? "text-bblack-0" : "text-white"}`}
              rel="no-referer"
            >
              Login
            </a>
          ) : null}
          <a
            onClick={() => {
              if (signUpFn) {
                signUpFn();
              }
            }}
            href={signUpLink}
          >
            <button className="py-2 rounded-md bg-bblue px-8 text-white">
              {signUpLabel ? signUpLabel : "Sign up"}
            </button>
          </a>
        </div>
        <span className="z-30 lg:hidden">
          {!showMenu ? (
            <AiOutlineMenu
              size={16}
              className="text-3xl ml-4 lg:hidden z-30"
              onClick={toggleMenu}
              color={isBgWhite ? "black" : "white"}
            />
          ) : (
            <AiOutlineClose
              size={16}
              className="text-xl ml-4 lg:hidden z-30"
              onClick={toggleMenu}
              color={isBgWhite ? "black" : "white"}
            />
          )}
        </span>
      </div>
    </div>
  );
};

export default TopBar;
