import frame01 from "../../assets/frame01.png";
import frame02 from "../../assets/frame02.png";
import frame03 from "../../assets/frame03.png";
import frame1 from "../../assets/frame1.png";
import frame2 from "../../assets/frame2.png";
import frame3 from "../../assets/frame3.png";
import contractor from "../../assets/contractor.png";
import projectmanager from "../../assets/projectmanager.png";
import projectowner from "../../assets/owner.png";

export const reasons = [
  {
    question: "Bid Management",
    image: [frame01, frame1],
    answer:
      "Conduct online competitive bidding in a wide pool of verified contractors and consultants.",
  },
  {
    question: "Project Tracking",
    image: [frame02, frame2],
    answer: "Get real time comprehensive status reports on your project.",
  },
  {
    question: "Quality Control",
    image: [frame03, frame3],
    answer:
      "Retain tight project control with smart triggers and phase gates to deliver on quality objectives.",
  },
];

type person = {
  colorScheme: "blue" | "green" | "orange";
  image: string;
  persona: string;
  description: string;
};
let personas: person[] = [
  {
    image: projectowner,
    persona: "Project Owner",
    description:
      "Get end-to-end project procurement that optimzes speed and quality at the right price.",
    colorScheme: "blue",
  },
  {
    image: contractor,
    persona: "Contractor/Consultant",
    description:
      "Gain access to bid on an endless pool of new projects at the speed of a click",
    colorScheme: "green",
  },
  {
    image: projectmanager,
    persona: "Project Manager",
    description:
      "Access the tools to deliver satisfaction to every client, every time.",
    colorScheme: "orange",
  },
];

export { personas };
