import React, { useEffect } from "react";
import Button from "components/shared/Button";
import { AiOutlineArrowRight } from "react-icons/ai";
import { reasons } from "./constants";
import Fotter from "../../components/shared/Fotter";
import PersonaCard from "components/shared/PersonaCard";
import { personas } from "./constants";
import stripes from "assets/stripes.png";
import hero from "assets/homepage.png";
import homepage2 from "assets/homepage2.png";
import NewHeader from "../../components/shared/NewHeader";

export default function Enterprise() {
  useEffect(() => {
    document.title = "home";
  }, []);
  const Reasons = React.Children.toArray(
    reasons.map((one, index) => (
      <div className="w-full">
        <div
          className={`w-full xl:w-[calc(100vw-((100vw-1234px)/2))] lg:justify-between flex items-center flex-col ${
            index % 2 ? "lg:flex-row-reverse ml-0" : "lg:flex-row mr-0"
          } mt-12 max-w-9xl mx-auto h-fit text-black `}
        >
          <section className="w-full xl:max-w-[380px] lg:justify-start xl:p-0 lg:w-auto p-5 md:px-20 lg:px-36 flex flex-col md:justify-center">
            <h5 className="font-DemiBold text-center lg:text-left text-3xl md:text-2xl lg:text-3xl">
              {one.question}
            </h5>
            <p className="text-lg text-bash text-center lg:text-left my-4">
              {one.answer}
            </p>
            <button className="flex items-center justify-center lg:justify-start text-borange font-Medium">
              <span>Get started</span>
              <AiOutlineArrowRight className="text-sm ml-2" />
            </button>
          </section>
          <img
            alt=""
            src={one.image[1]}
            className="w-9/12 lg:w-1/2 lg:hidden"
          />
          <img
            alt=""
            src={one.image[0]}
            className="w-9/12 lg:w-1/2 hidden lg:block"
          />
        </div>
      </div>
    ))
  );

  return (
    <div className=" relative flex flex-col items-center justify-start  bg-[#0D182C]">
      <div className="w-full flex relative items-center flex-col h-full">
        <NewHeader
          isBgWhite={false}
          signInLink={`https://auth.${process.env.REACT_APP_DOMAIN}/signin`}
          signUpLink={`https://auth.${process.env.REACT_APP_DOMAIN}/select-persona?type=signup`}
          className=" text-white"
        />

        <div className="w-full">
          <div className="relative   flex flex-col xl:min-h-[700px] lg:flex-row lg:justify-end items-center lg:items-end  lg:px-0 mt-5 mx-auto lg:ml-0 ">
            <div className=" lg:max-w-[calc(100vw-((100vw-1234px)/2))] xl:w-[calc(100vw-((100vw-1234px)/2))] flex flex-col lg:flex-row lg:justify-between items-center  mx-auto lg:ml-3  lg:mr-0">
              <div className=" w-4/5 lg:w-1/2 items-center flex flex-col lg:items-start justify-start lg:max-w-lg 2xl:max-w-2xl lg:justify-start relative z-10">
                <span className="font-Bold text-4xl xl:text-7xl text-center xl:text-left text-white lg:text-left">
                  Manage Your Construction Projects Remotely
                </span>
                <span className=" text-ashShade-11 mt-5 text-base md:text-xl  text-center lg:text-left w-full">
                  Get projects done on time and on budget from anywhere.
                </span>
                <div className="mt-8 w-full flex items-center justify-center lg:justify-start">
                  <Button
                    label="Sign Up"
                    className="lg:w-auto m-auto lg:m-0 "
                    onClick={() =>
                      window.location.assign(
                        `https://auth.${process.env.REACT_APP_DOMAIN}/select-persona?type=signup`
                      )
                    }
                  />
                </div>
              </div>
              <img
                src={window.innerWidth === 968 ? hero : homepage2}
                alt=""
                className="w-11/12 object-cover mt-10  md:mt-0 md:w-1/2 lg:w-3/5 lg:self-end relative z-10"
              />
            </div>

            <img
              src={stripes}
              alt=""
              className="absolute w-full h-full -top-0 left-0"
            />
          </div>
          <div className="w-full flex bg-white flex-col text-black relative p-10 sm:p-24 md:p-32  ">
            <div className="max-w-[1234px] m-auto">
              <div className="mx-3 md:mx-auto mb-3 text-3xl text-center md:text-4xl font-DemiBold">
                Who is it for?
              </div>
              <div className="mx-4 mb-16 md:mx-auto max-w-[843px] text-base md:text-xl text-justify md:text-center text-bash">
                Bnkle offers clients, consultants, contractors, and vendors
                tools that dramatically optimizes their project management
                activities.
              </div>
              <div className="w-full m-auto">
                <div className="grid grid-cols-1  w-full lg:grid-cols-3 sm:gap-y-4 md:grid-cols-2  md:gap-y-16 md:gap-x-4  lg:gap-4 pb-24">
                  {personas.map((m, i) => (
                    <PersonaCard {...m} key={i} />
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="w-full flex pt-20 md:pt-28 flex-col bg-pbg text-black relative pb-16">
            <div className="mx-auto mt-16 mb-3 text-base md:text-xl font-DemiBold text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-blue-900">
              Features
            </div>
            <div className="mx-3 md:mx-auto mb-3 text-3xl text-center md:text-4xl font-DemiBold">
              Here is why you should use bnkle
            </div>
            <div className="mx-4 md:mx-auto max-w-[843px] text-center text-base md:text-xl  md:text-center text-bash">
              You can finally access the tools and features that help you
              seamlessly deliver a project, all in one place. Powerful tools
              like…
            </div>
            {Reasons}
          </div>
          {/* <div className="-mb-20" /> */}
          <Fotter />
        </div>
      </div>
    </div>
  );
}
