import { AiOutlineCheck, AiOutlineLoading } from "react-icons/ai";
import { CSSProperties, FC, ReactNode } from "react";

interface Props {
  label: string | ReactNode;
  isLoading?: boolean;
  onClick?: () => void;
  type?: "primary" | "secondary" | "muted" | "danger" | "transparent";
  className?: string;
  LeftIcon?: ReactNode;
  RightIcon?: ReactNode;
  style?: CSSProperties;
  textStyle?: string;
  key?: string;
  disabled?: boolean;
  success?: boolean;
}

export const btnStyle =
  "bg-bblue rounded-lg py-2 px-8 border border-bblue font-Medium text-lg text-white cursor-pointer";

const Button: FC<Props> = ({
  success = false,
  label,
  key,
  onClick,
  type,
  className,
  RightIcon,
  LeftIcon,
  style,
  textStyle,
  disabled = false,
  isLoading = false,
}) => {
  const onClickHandler = () => {
    // if not loading, emit the event
    if (!isLoading && onClick) {
      onClick();
    }
  };

  const typeBasedStyles = () => {
    switch (type) {
      case "secondary":
        return "border border-black hover:border-transparent bg-transparent hover:bg-pbg text--ashShade-2 text-black";
      case "danger":
        return "bg-bred";
      case "muted":
        return "bg-ashShade-3 border !border-ashShade-2 text-ashShade-2";
      case "transparent":
        return "bg-transparent border-0 text-bblue";
      default:
        break;
    }
  };

  if (success)
    return (
      <button
        key={key}
        data-testid="btn"
        onClick={onClickHandler}
        style={style}
        className={` ${type} Button bg-gradient-to-l from-green-700 to-green-600`}
      >
        <AiOutlineCheck className="text-white" />
      </button>
    );

  return (
    <button
      key={key}
      style={style}
      data-testid="btn"
      onClick={onClickHandler}
      disabled={disabled || type === "muted"}
      className={`${btnStyle} ${type} Button ${typeBasedStyles()} ${className} relative flex items-center justify-center hover:opacity-90`}
    >
      {LeftIcon}
      <AiOutlineLoading
        className={`animate-spin mx-2 my-1 absolute ${
          !isLoading && "opacity-0"
        } cursor-pointer`}
      />
      <label
        className={`${
          isLoading && "opacity-0"
        } cursor-pointer text-sm md:text-base ${textStyle}`}
      >
        {label}
      </label>
      {RightIcon}
    </button>
  );
};

export default Button;
