import referralShapes from "../../assets/svg/referral-shapes.svg";
import referralColorFilter from "../../assets/svg/referral-colorfilter.svg";
import referralBrush from "../../assets/svg/referral-brush.svg";
import referralFlash from "../../assets/svg/referral-flash.svg";
import referralCopy from "../../assets/svg/referral-copy.svg";
import referralOmega from "../../assets/svg/referral-omega.svg";
import referralEraser from "../../assets/svg/referral-eraser.svg";

export const referralProcess = [
    {
        id: 1,
        img: referralShapes,
        title: "Get Your Unique Referral Link",
        desc: "Here, you'll find your unique referral link - this is the magic link that tracks your referrals and rewards.",
    },
    {
        id: 2,
        img: referralColorFilter,
        title: "Share Your Referral Link",
        desc: "Copy your unique link or use the share buttons provided to spread the word.Share it with friends, family, or anyone who would benefit from our amazing services.",
    },
    {
        id: 3,
        img: referralBrush,
        title: "Friends Sign Up Through Your Link",
        desc: "When someone clicks on your referral link and signs up for our services, they become your referred friend. Our system will recognise the referral and attribute it to your account.",
    },
    {
        id: 4,
        img: referralFlash,
        title: "Track Your Referrals",
        desc: "Visit your Referral Dashboard to keep tabs on your referral activity.See how many friends have signed up, track their progress, and watch your rewards grow.",
    },
    {
        id: 5,
        img: referralCopy,
        title: "Earn Rewards for Successful Referrals",
        desc: "As your referred friends engage with our services - start their project - you earn rewards. The more friends who join and take action, the more rewards you unlock.",
    },
    {
        id: 6,
        img: referralOmega,
        title: "Redeem Your Rewards",
        desc: "Once you've accumulated enough rewards, you can redeem your cash rewards. Check the redemption options in your Referral Dashboard.",
    },
    {
        id: 7,
        img: referralEraser,
        title: "Spread the Love, Keep Earning",
        desc: "There's no limit to the number of friends you can refer or the rewards you can earn. Keep spreading the word, and keep enjoying the benefits of our Referral Program.",
    },
];
