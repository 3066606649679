interface Props {
    icon?: React.ReactNode;
    src?: string;
    title: string;
}

const Tag = ({ icon, title, src }: Props) => {
    return (
        <div className='flex gap-2 items-center w-max py-[6px] px-3 text-bblue text-sm font-SatoshiMedium font-medium border border-bblue rounded-3xl'>
            {icon && icon}
            {src && <img src={src} alt='' />}
            {title}
        </div>
    );
};
export default Tag;
