import Footer from "components/newShared/Footer";
import HowItWorks from "./HowItWorks";
import referralImg from "../../assets/newreferral.png";
import playBtnIcon from "../../assets/svg/playBtn.svg";
import Header from "components/newShared/Header";
import { FiArrowRight } from "react-icons/fi";
import GetInTouch from "components/newShared/GetInTouch";

const HomePage = () => {
    const Hero = () => (
        <div className='flex items-center gap-4 referral-container lg:text-start py-8 md:py-16 xl:py-12'>
            <div className='flex flex-col flex-[3]'>
                <div className='md:hidden bg-gradient-to-r from-[#66BDE4] to-[#437ADB] bg-clip-text text-transparent text-gradient font-semibold text-center'>
                    Features
                </div>
                <h1 className='text-5xl leading-[60px] pb-6 md:pb-0 lg:text-5xl xl:text-6xl text-white text-center lg:text-start font-SatoshiBold  xl:leading-[72px]'>
                    Share, refer, earn up to $300!
                </h1>
                <p className='pt-16 pb-5 md:pt-4 md:text-center lg:text-start lg:pb-6 text-ashShade-5 font-SatoshiRegular text-lg md:text-xl'>
                    Get all the tools and resources you need to manage multiple
                    projects and project teams all in one place.
                </p>
                <div className='md:hidden flex gap-4 items-center text-borange pb-16'>
                    Get started
                    <span>
                        <FiArrowRight color='borange' />
                    </span>
                </div>
                <div className='w-full xxs-screen md:w-auto flex items-center gap-6 justify-between md:justify-center lg:justify-start'>
                    <a
                        href={`https://auth.${process.env.REACT_APP_DOMAIN}/project-owner/signup?fromreferralPage=true`}
                    >
                        <button className='bg-gradient-to-r to-[#FBB040] from-[#EF4136] xs-screen px-6 2xl:px-9 py-3 font-medium text-white md:text-lg rounded-md font-SatoshiMedium'>
                            Refer now
                        </button>
                    </a>
                    <button
                        type='button'
                        className='flex items-center gap-2 font-SatoshiMedium'
                    >
                        <img
                            src={playBtnIcon}
                            alt=''
                            className='w-10 h-10 2xl:w-[52px] 2xl:h-[52px]'
                        />
                        <span className='text-sm md:text-base text-white'>
                            How our referral work
                        </span>
                    </button>
                </div>
            </div>
            <div className='hidden lg:flex flex-[3]'>
                <img src={referralImg} alt='' />
            </div>
        </div>
    );

    return (
        <div>
            <>
                <Header
                    removeReferral
                    signInLink={`https://auth.${process.env.REACT_APP_DOMAIN}/signin`}
                    signUpLink={`https://auth.${process.env.REACT_APP_DOMAIN}/project-owner/signup?fromreferralPage=true`}
                    signUpLabel='Refer now'
                />
            </>
            <div className='w-full h-full bg-blueShades-0 px-5 md:px-16'>
                <Hero />
            </div>
            <div className='w-full bg-bgSecondary px-5 md:px-16 py-20'>
                <HowItWorks />
            </div>
            <div className='w-full pt-20'>
                <GetInTouch />
                <Footer />
            </div>
        </div>
    );
};

export default HomePage;
