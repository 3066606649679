import image1 from '../../assets/image1.svg'
import image11 from '../../assets/image2.svg'
import image2 from '../../assets/image14.svg'
import image12 from '../../assets/image51.svg'
import image3 from '../../assets/image41.svg'
import image13 from '../../assets/image43.svg'
import image4 from '../../assets/image15.svg'
import image14 from '../../assets/image17.svg'
import image5 from '../../assets/image13.svg'
import image15 from '../../assets/image18.svg'

export const reasons = [
    {
      question: "Choose from Verified Contractors and Consultants.",
      image: { mobile: image11, desktop: image1 },
      answer:
        "Access our curated pool of vetted contractors and consultants. Our rating system ensures that you find the perfect match for your project requirements.",
    },
    {
      question: "Receive and Evaluate Bids Online",
      image: { mobile: image12, desktop: image2 },
      answer:
        "Conduct transparent online bidding process: Automate the tender process from issuing invitations to bid, to receiving and analysing bids..",
    },
    {
      question: "Gain clarity and control of your Project",
      image: { mobile: image13, desktop: image3 },
      answer:
        "Our cloud based repository of project documents and decisions ensures that you can recall and review every detail of your project real time.",
    },
    {
      question: "Eliminate the risk of financial loss.",
      image: { mobile: image14, desktop: image4 },
      answer:
        "Contractors provide insurance to cover the funds you disburse for the constuction of your project.",
    },
    {
      question: "Real time project tracking",
      image: { mobile: image15, desktop: image5 },
      answer:
        "Get increased visibility on project progress and allows you to quickly identify any issues or challenges and adjust their strategies accordingly",
    },
  ];