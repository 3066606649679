import logo from "assets/images/logo.png";
import { useNavigate, Link } from "react-router-dom";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

interface Prop {
    className?: string;
    signInLink?: string;
    signUpLink?: string;
    showMenu: boolean;
    toggleMenu: () => void;
    display: string;
    setDisplay: any;
    links: any;
    signUpFn?: any;
    signInFn?: any;
    showLogIn?: boolean;
    signUpLabel?: string;
    removeReferral?: boolean;
}

const TopBar = ({
    className,
    signInLink,
    signUpLink,
    toggleMenu,
    showMenu,
    display,
    setDisplay,
    links,
    signInFn,
    signUpFn,
    showLogIn,
    signUpLabel,
    removeReferral,
}: Prop) => {
    let navigation = useNavigate();
    return (
        <div
            className={
                `sticky top-0 flex py-5  m-auto  w-full hover:cursor-pointer self-center items-center  justify-between lg:h-20 px-5 md:px-16  font-inter font-medium` +
                className
            }
        >
            {" "}
            <div className='2xl:m-auto m-auto max-w-[1234px] w-full flex justify-between  items-center'>
                <div className='flex items-center '>
                    <img
                        src={logo}
                        alt='bnkle'
                        className='md:mr-12'
                        onClick={() => navigation("/")}
                    />
                    <div className=' hidden lg:flex items-center space-x-6'>
                        {links.map((m: any, i: number) => (
                            <span
                                onClick={() => {
                                    if (i == 0 || i == 1) {
                                        if (display == m) {
                                            setDisplay("");
                                        } else {
                                            setDisplay(m);
                                        }
                                    }
                                }}
                                className='hidden lg:flex items-center text-headerblue'
                                key={i}
                            >
                                {m}
                                {(i === 0 || i === 1) && (
                                    <span className='ml-1'>
                                        {display === links[i] ? (
                                            <FiChevronUp
                                                size={16}
                                                color={"headerblue"}
                                            />
                                        ) : (
                                            <FiChevronDown
                                                size={16}
                                                color={"headerblue"}
                                            />
                                        )}
                                    </span>
                                )}
                            </span>
                        ))}
                        <span>
                            <a
                                href={`https://calculator.${process.env.REACT_APP_DOMAIN}`}
                                target='_blank'
                                className='text-headerblue'
                            >
                                Cost Calculator
                            </a>
                        </span>
                        {!removeReferral && (
                            <span
                                onClick={() => {
                                    navigation("/referral");
                                }}
                                className='text-headerblue'
                            >
                                Refer-to-earn
                            </span>
                        )}
                        <span>
                            <a href='' className='text-headerblue'>
                                Pricing
                            </a>
                        </span>
                    </div>
                </div>

                <div className='lg:flex space-x-6 hidden items-center'>
                    <Link to='/contact'>Contact us</Link>
                    {showLogIn === undefined || showLogIn === true ? (
                        <a
                            onClick={() => {
                                if (signInFn) {
                                    signInFn();
                                }
                            }}
                            href={signInLink}
                            className='text-headerblue border border-ashShade-12 py-[7px] px-4 rounded-lg hover:bg-ashShade-13 active:bg-ashShade-14'
                            rel='no-referrer'
                        >
                            Login
                        </a>
                    ) : null}
                    <a
                        onClick={() => {
                            if (signUpFn) {
                                signUpFn();
                            }
                        }}
                        href={signUpLink}
                    >
                        <button className='py-2 rounded-md bg-bblue px-6 text-white hover:bg-blueShades-1 active:bg-blueShades-0'>
                            {signUpLabel ? signUpLabel : "Sign up"}
                        </button>
                    </a>
                </div>
                <span className='z-30 lg:hidden'>
                    {!showMenu ? (
                        <AiOutlineMenu
                            size={16}
                            className='text-3xl ml-4 lg:hidden z-30'
                            onClick={toggleMenu}
                            color={"headerblue"}
                        />
                    ) : (
                        <AiOutlineClose
                            size={16}
                            className='text-xl ml-4 lg:hidden z-30'
                            onClick={toggleMenu}
                            color={"headerblue"}
                        />
                    )}
                </span>
            </div>
        </div>
    );
};

export default TopBar;
