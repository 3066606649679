import { Props } from "./NavBarWithOptions";

import { TbMathSymbols } from "react-icons/tb";
import { HiOutlineNewspaper } from "react-icons/hi";
import { FiUser, FiUsers } from "react-icons/fi";
import { RiBuilding4Line } from "react-icons/ri";

let navlinks: Props[] = [
  {
    label: "Solutions",
    sublinks: [
      {
        label: "Private Project Owners",
        link: "/",
      },
      {
        label: "Enterprise Project Owners",
        link: "/enterprise",
      },
      {
        label: "Contractor",
        link: "/contractor",
      },
      {
        label: "Consultant",
        link: "/consultant",
      },
    ],
  },
  {
    label: "Resources",
    sublinks: [
      {
        label: "Calculator",
        link: "/",
      },
      {
        label: "Blog",
        link: "/",
      },
    ],
  },
  {
    label: "Pricing",
    sublinks: [],
  },
];

let sublinks = {
  Resources: [
    {
      label: "Blog",
      link: "/",
      icon: HiOutlineNewspaper,
      iconbg: "bg-[#ECF2FB]",
      iconcolor: "#437ADB",
    },
    {
      label: "Cost Calculator",
      link: `https://calculator.${process.env.REACT_APP_DOMAIN}`,
      icon: TbMathSymbols,
      iconbg: "bg-[#FFF3EB]",
      iconcolor: "#FF8A34",
      isExternalLink: true,
    },
  ],
  Solutions: [
    {
      label: "Private Project Owners",
      link: "/owner",
      icon: FiUser,
      iconbg: "bg-[#ECF2FB]",
      iconcolor: "#437ADB",
    },
    {
      label: "Enterprise Project Owners",
      link: "/enterprise",
      icon: RiBuilding4Line,
      iconbg: "bg-[#FFF3EB]",
      iconcolor: "#FF8A34",
    },
    {
      label: "Contractor",
      link: "/contractor",
      icon: FiUsers,
      iconbg: "bg-[#E9F6F2]",
      iconcolor: "#26A87D",
    },
    {
      label: "Consultant",
      link: "/consultant",
      icon: FiUsers,
      iconbg: "bg-[#E9F6F2]",
      iconcolor: "#26A87D",
    },
  ],
};

let sublinksCopy = {
  "/contractor":
    "Gain access to bid on an endless pool of new projects at the speed of a click",
  "/consultant":
    "Gain access to bid on an endless pool of new projects at the speed of a click",
  "/owner": "Get end-to-end project procurement that optimizes speed and quality at the right price.",
  "/enterprise":
    "Access the tools to deliver satisfaction to every client, every time.",
};

export { navlinks, sublinks, sublinksCopy };
