import React, { Children, createContext, ReactNode, useState } from "react";

let AuthContext = createContext<any>(null);

interface Prop {
  children: ReactNode;
}

const Index = ({ children }: Prop) => {
  const [auth, setAuth] = useState(false);
  return (
    <AuthContext.Provider value={[auth, setAuth]}>
      {children}
    </AuthContext.Provider>
  );
};
export {AuthContext}
export default Index;
