import React from "react";
import house from "assets/bnklehouse.png";
import backgroundstripes from "assets/stripes.png";
import Button from "../../components/shared/Button";
import Fotter from "../../components/shared/Fotter";
import Carousel from "../../components/shared/Carousel";
import { copies } from "./constants";
import NewHeader from "components/shared/NewHeader";

let signUpLink = `https://auth.${process.env.REACT_APP_DOMAIN}/project-owner/signup`;
const Index = () => {
  React.useEffect(() => {
    document.title = "Bnkle";
  }, []);

  return (
    <div className=" relative w-screen flex flex-col items-center justify-start ">
      <div className="w-full flex relative items-center flex-col h-full">
        <NewHeader
          isBgWhite
          signInLink={`https://auth.${process.env.REACT_APP_DOMAIN}/signin`}
          signUpLink={`https://auth.${process.env.REACT_APP_DOMAIN}/project-owner/signup`}
        />
        <div className="w-full items-center">
          <div className="   px-2 md:px-0 mt-10 md:mt-20 mb-10 flex flex-col mx-auto max-w-sm md:max-w-[700px] xl:max-w-[1102px] text-center relative">
            <span className="font-Bold text-3xl md:text-5xl xl:text-7xl">
              Build your dream project. <br /> Hassle free.
            </span>
            <span className=" text-bash mt-4 text-base md:text-lg xl:text-xl self-center w-11/12 md:w-auto md:max-w-[600px]">
              Access high quality vetted professionals, conduct online bids,
              procure materials and get real time progress reports from anywhere
              in the world.
            </span>
            <div className="mt-8 md:self-center">
              <Button
                label="Start here"
                className="md:w-auto w-11/12"
                onClick={() =>
                  window.location.assign(
                    `https://auth.${process.env.REACT_APP_DOMAIN}/project-owner/signup`
                  )
                }
              />
            </div>
            <img
              src={backgroundstripes}
              alt=""
              className="w-screen h-screen md:hidden absolute object-cover -top-20 left-0 -z-20"
            />
          </div>
          <div className="relative -top-5 md:-top-48 xl:-top-52 -z-10">
            <img src={house} alt="" className="w-full relative" />
            <div className="w-full absolute bottom-0 h-1/2 bg-gradient-to-b from-transparent to-ashShade-10 "></div>
          </div>
          <Carousel hasTopOffSet {...{ copies }} link={signUpLink} />
          <div className="md:-mb-80 xl:-mb-52" />

          <Fotter />
        </div>
      </div>
    </div>
  );
};

export default Index;
