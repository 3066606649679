import React from "react";

interface Props {
  image: string;
  persona: string;
  description: string;
  colorScheme?: string;
}
const schemes: any = {
  blue: ["bg-blueShades-1", "bg-blueShades-2", "text-bblue"],
  green: ["bg-bgreen-1", "bg-bgreen-2", "text-bgreen-3"],
  orange: ["bg-orangeShades-0", "bg-orangeShades-1", "text-borange"],
};
const PersonaCard = ({ image, persona, description, colorScheme }: Props) => {
  return (
    <div className=" w-full pt-10 ">
      <div className={`w-full relative rounded-2xl bg-landingblue   `}>
        <img src={image} alt="" className="relative -top-10 w-full " />
      </div>
      <div
        className={`w-full relative -top-10  p-6  rounded-b-2xl ${
          colorScheme ? schemes[colorScheme][1] : " bg-lightShades-0"
        }  `}
      >
        <p className=" font-DemiBold text-xl xl:text-2xl 2xl:text-3xl text-bblack-0 mb-4">
          {persona}
        </p>
        <p className=" text-bash text-lg mb-3 2xl:text-xl">{description}</p>
        <p
          className={` ${
            colorScheme ? schemes[colorScheme][2] : " text-borange"
          }`}
        >
          <a
            href={
              persona === "Contractor/Consultant" ||
              persona === "Project Manager"
                ? "/contractor"
                : "/owner"
            }
          >
            Learn More &#8594;
          </a>
        </p>
      </div>
    </div>
  );
};

export default PersonaCard;
