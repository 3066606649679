import React, { useState } from "react";

interface Prop {
  question: string;
  answer: string;
  isLast?: boolean;
}
const Faq = ({ question, answer }: Prop) => {
  const [showAnswer, setShowAnswer] = useState(false);
  return (
    <div className="cursor-pointer">
      <div
        onClick={() => setShowAnswer(!showAnswer)}
        className={`flex justify-between ${
          showAnswer ? "border-b-0 pt-6 pb-2" : "border-b-bash py-6"
        } border-b  relative`}
      >
        <span className=" text-bblack-0 hover:opacity-70 text-lg font-DemiBold w-11/12">
          {question}
        </span>
        <span
          className={`text-[#2C3E50] absolute right-0 hover:opacity-70 transform transition ${
            showAnswer && "rotate-45 rotate"
          } text-xl`}
        >
          &#43;
        </span>
      </div>
      <div
        className={`
       border-b-bash border-b
          ${
            !showAnswer
              ? "zero-height  overflow-hidden"
              : "auto-height  overflow-y-scroll"
          }
        `}
      >
        <p className="text-bash  pb-4 ">{answer}</p>
      </div>
    </div>
  );
};

const RefFaq = ({ question, answer, isLast }: Prop) => {
  const [showAnswer, setShowAnswer] = useState(false);
  const toggle = () => {
    setShowAnswer(!showAnswer);
  };
  return (
    <div
      className={` ${isLast ? "" : "border-b border-b-white"} cursor-pointer  flex items-center text-white`}
    >
      <span
        onClick={toggle}
        className={`t   hover:opacity-70 transform transition ${
          showAnswer && "rotate-45 rotate"
        } text-5xl`}
      >
        &#43;
      </span>
      <div className="ml-3 lg:ml-10">
        <div
          onClick={toggle}
          className={`flex justify-between ${
            showAnswer ? "border-b-0  pb-2" : " py-6"
          }  relative`}
        >
          <span className=" font-bold  hover:opacity-70 text-lg md:text-xl lg:text-2xl font-DemiBold w-11/12">
            {question}
          </span>
        </div>
        <div
          className={`
     
          ${
            !showAnswer
              ? "zero-height overflow-hidden"
              : "auto-height mt-3  overflow-y-scroll"
          }
        `}
        >
          <p className=" md:text-lg  lg:text-xl pb-4 ">{answer}</p>
        </div>
      </div>
    </div>
  );
};

export { RefFaq };

export default Faq;
