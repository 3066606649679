const ContactForm = () => {
    return (
        <form
            className='flex flex-col gap-6 w-full lg:w-6/12 bg-white text-bblack-1 px-4 md:px-8 pt-8 md:pt-14 pb-8 rounded-xl'
            onSubmit={(e) => e.preventDefault()}
        >
            <div className='flex flex-col md:flex-row gap-6 items-center w-full'>
                <div className='flex flex-col gap-2 w-full md:w-1/2'>
                    <label
                        htmlFor='firstname'
                        className='font-inter font-medium text-sm'
                    >
                        First name
                    </label>
                    <input
                        type='text'
                        id='firstname'
                        name='firstname'
                        placeholder='John'
                        className='px-[14px] py-[10px] border border-ashShade-15 w-full rounded-lg placeholder:text-base placeholder:text-bash focus:outline-0 focus:border-bblue'
                    />
                </div>
                <div className='flex flex-col gap-2 w-full md:w-1/2'>
                    <label
                        htmlFor='lastname'
                        className='font-inter font-medium text-sm'
                    >
                        Last name
                    </label>
                    <input
                        type='text'
                        id='lastname'
                        name='lastname'
                        placeholder='Doe'
                        className='px-[14px] py-[10px] border border-ashShade-15 w-full rounded-lg placeholder:text-base placeholder:text-bash focus:outline-0 focus:border-bblue'
                    />
                </div>
            </div>
            <div className='flex flex-col gap-2'>
                <label
                    htmlFor='email'
                    className='font-inter font-medium text-sm'
                >
                    Email
                </label>
                <input
                    type='text'
                    id='email'
                    name='email'
                    placeholder='name@example.com'
                    className='px-[14px] py-[10px] border border-ashShade-15 w-full rounded-lg placeholder:text-base placeholder:text-bash focus:outline-0 focus:border-bblue'
                />
            </div>
            <div className='flex flex-col gap-2'>
                <label
                    htmlFor='message'
                    className='font-inter font-medium text-sm'
                >
                    Description
                </label>
                <textarea
                    id='message'
                    name='message'
                    placeholder='Enter a description...'
                    className='px-[14px] py-[10px] border border-ashShade-15 w-full min-h-32 rounded-lg placeholder:text-base placeholder:text-bash resize-none focus:outline-0 focus:border-bblue'
                />
            </div>
            <button
                type='submit'
                className='bg-bblue text-white py-3 rounded-lg font-SatoshiRegular font-medium hover:bg-blueShades-1 active:bg-blueShades-0'
            >
                Send message
            </button>
        </form>
    );
};
export default ContactForm;
