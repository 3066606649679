import Button from "../../components/shared/Button";
import icon from "../../assets/notFound.svg";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft, AiOutlineLoading } from "react-icons/ai";
import { centerd, flexer } from "../../constants/globalStyles";
import { useContext, useEffect, useState } from "react";
import logo from "../../assets/bnklelogo.svg";
import { AuthContext } from "context";

export default function NotFound() {
  const navigation = useNavigate();
  const [imgHasLoaded, setImageLoader] = useState(false);
  let [auth] = useContext(AuthContext);
  let isUsebnkle = process.env.REACT_APP_IS_BNKLE === "false" ? true : false;
  useEffect(() => {
    const img = new Image();

    img.src = icon;

    img.onload = () => {
      setImageLoader(true);
    };
  }, []);

  if (!imgHasLoaded)
    return (
      <div className={"w-full h-screen" + centerd}>
        <AiOutlineLoading className="text-bblue text-5xl animate-spin" />
      </div>
    );

  return (
    <div className={"h-screen  w-screen overflow-y-auto"}>
      <div className={" mx-auto p-10  "}>
        <img src={logo} alt="" className="w-24 mb-8" />
        <div className="w-full flex flex-col-reverse lg:flex lg:flex-row justify-between items-center ">
          <div className="lg:w-1/2 w-full">
            {!!!isUsebnkle && (
              <p className="text-base text-bblue font-Medium lg:my-3 ">
                404 error
              </p>
            )}
            <h1 className="capitalize lg:my-3 font-Medium text-3xl lg:text-[64px]">
              {isUsebnkle ? "Site under construction" : "Page not found"}
            </h1>
            {isUsebnkle ? null : (
              <>
                <p className="text-lg text-bash my-7">
                  Sorry, the page you are looking for doesn’t exist. Here are
                  some helpful links:
                </p>
                <div className="flex items-center">
                  <Button
                    onClick={() => navigation(-1)}
                    LeftIcon={
                      <AiOutlineArrowLeft className="text-xs sm:text-sm mr-2" />
                    }
                    type="secondary"
                    label="Go Back"
                  />
                  <Button
                    onClick={() => navigation("/")}
                    label="Take me home"
                    className="ml-5 text-xs sm:text-sm"
                  />
                </div>
              </>
            )}
          </div>
          <div className="w-full xl:max-h-full lg:w-1/2 self-center my-4">
            <img
              className="w-full object-contain  "
              src={icon}
              alt=""
              loading="lazy"
              decoding="async"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
