import { useState } from "react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

interface Props {
    question: string;
    answer: string;
}

const Faq = ({ question, answer }: Props) => {
    const [showAnswer, setShowAnswer] = useState(false);
    const toggleQuestion = () => {
        setShowAnswer(!showAnswer);
    };

    return (
        <div className='cursor-pointer'>
            <div
                onClick={toggleQuestion}
                className={`bg-ashShade-0 md:bg-transparent flex justify-between items-center px-4 md:px-0 md:rounded-none ${
                    showAnswer
                        ? "py-3 md:pt-6 rounded-t-xl"
                        : "py-3 md:py-6 rounded-xl"
                }  relative`}
            >
                <h3 className='text-lg md:text-xl xl:text-2xl font-SatoshiMedium text-bblack-1'>
                    {question}
                </h3>
                {showAnswer ? <AiOutlineMinus /> : <AiOutlinePlus />}
            </div>
            <div
                className={`transition-all duration-300 ease-in-out ${!showAnswer ? "max-h-0  opacity-0" : "faq-mobile-border max-h-[500px] opacity-100 px-2 md:px-0 pt-2 pb-2 md:pb-6"}
        `}
            >
                <p className='text-bash xl:text-lg font-medium'>{answer}</p>
            </div>
            <div className='hidden md:block w-full h-[1px] bg-ashShade-4'></div>
        </div>
    );
};
export default Faq;
