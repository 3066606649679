import locationIcon from "assets/svg/location.svg";

export const addresses = [
    {
        id: 1,
        name: "Nigeria office",
        location:
            "401 The Epicentre Lifestyle, 1 Zilly Aggrey Drive, Karmo District, Abuja",
        icon: locationIcon,
    },
    {
        id: 2,
        name: "Canada office",
        location:
            "Innovation Place, 116 Research Drive Saskatoon, Saskatchewan",
        icon: locationIcon,
    },
];

export const questions = [
    {
        id: 1,
        question: "How much does it cost to sign up?",
        answer: "Our Standard Plan costs $50 per month through the duration of your project. For details on our Premium Plan which includes a dedicated project manager for your project, please contact our sales team.",
    },
    {
        id: 2,
        question: "Is every project executed on bnkle insured?",
        answer: "Insurance is provided on demand. Clients can indicate that they require contractors to provide a bond to cover their project.",
    },
];
