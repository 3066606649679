import { addresses } from "./constants";
import callIcon from "assets/svg/call-calling.svg";

const ContactInfo = () => {
    return (
        <div className='grid gap-6 font-SatoshiRegular text-bblack-1'>
            {addresses.map((address) => {
                const { id, name, location, icon } = address;
                return (
                    <div
                        key={id}
                        className='flex gap-6 items-center bg-white py-3 px-6 rounded-xl'
                    >
                        <div>
                            <img src={icon} alt='' />
                        </div>
                        <div className='flex flex-col'>
                            <span className='text-sm text-bash'>{name}</span>
                            <span className='font-medium leading-6'>
                                {location}
                            </span>
                        </div>
                    </div>
                );
            })}
            <div className='flex gap-6 items-center bg-white py-3 px-6 rounded-xl'>
                <div>
                    <img src={callIcon} alt='' />
                </div>
                <div className='flex flex-col'>
                    <span className='text-sm text-bash'>Call us</span>
                    <span className='text-lg text-bash'>
                        Mon - Fri, 9am - 5pm
                    </span>
                    <span className='font-medium'>+234703 610 8580</span>
                </div>
            </div>
        </div>
    );
};
export default ContactInfo;
