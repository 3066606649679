import { referralProcess } from "./constants";

const HowItWorks = () => {
    return (
        <div className='flex flex-col gap-10 lg:gap-20 referral-container bg-bgSecondary'>
            <h2 className='text-4xl lg:text-5xl font-SatoshiMedium'>
                How it works
            </h2>
            <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-4 xl:gap-5'>
                {referralProcess.map((item) => {
                    const { id, img, title, desc } = item;
                    return (
                        <div
                            key={id}
                            className='flex flex-col items-start gap-4 p-6 bg-white rounded-2xl'
                        >
                            <img
                                src={img}
                                alt=''
                                className='w-16 h-16 2xl:w-[72px] 2xl:h-[72px]'
                            />
                            <h3 className='font-SatoshiBold xl:text-lg'>
                                {title}
                            </h3>
                            <p className='text-LIGHT_TEXT font-SatoshiRegular xl:text-lg'>
                                {desc}
                            </p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default HowItWorks;
