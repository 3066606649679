import React, { useState } from "react";
import lightlogo from "assets/bnklebetalight.svg";
import darklogo from "assets/bnklebetadark.svg";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { sublinks, sublinksCopy } from "./constants";
import TopBar from "./TopBar";
import { useLocation } from "react-router-dom";

interface Props {
  className?: string;
  isBgWhite?: boolean;
  signInLink?: string;
  signUpLink?: string;
  signInFn?: any;
  signUpFn?: any;
  showLogIn?: boolean;
  signUpLabel?: string;
  removeReferral?: boolean;
}
let mysubs = sublinks as any;
let links = ["Solutions", "Resources"];
let subcopies = sublinksCopy as any;

let solutionsPersonaCopy = {
  "/owner":
    "Get end-to-end project procurement that optimizes speed and quality at the right price.",
};

export default function NewHeader({
  signInLink,
  signUpLink,
  isBgWhite,
  className,
  signInFn,
  signUpFn,
  showLogIn,
  signUpLabel,
  removeReferral,
}: Props) {
  const navigation = useNavigate();
  const [showMenu, setMenu] = useState(false);
  const [display, setDisplay] = useState("");
  const toggleMenu = () => {
    setMenu((prev) => !prev);
  };
  return (
    <>
      <TopBar
        display={display}
        setDisplay={setDisplay}
        className={className + " z-30"}
        signInLink={signInLink}
        signUpLink={signUpLink}
        showMenu={showMenu}
        toggleMenu={toggleMenu}
        links={links}
        isBgWhite={isBgWhite}
        signInFn={signInFn}
        signUpFn={signUpFn}
        showLogIn={showLogIn}
        signUpLabel={signUpLabel}
        removeReferral={removeReferral}
      />

      {showMenu && (
        <div
          onClick={() => setMenu(!showMenu)}
          className={`w-full lg:hidden z-[99] absolute top-0 bg-black ${
            isBgWhite ? "bg-opacity-90" : "bg-opacity-70"
          } flex flex-col items-center  left-0 p-auto text-lg`}
        >
          <div
            className={
              "flex py-5 px-3 m-auto  w-full hover:cursor-pointer self-center  md:px-36 items-center  justify-between z-30 " +
              className
            }
          >
            {" "}
            <div className="2xl:m-auto max-w-[1234px] w-full flex justify-between  items-center">
              <div className="flex items-center ">
                <img
                  src={isBgWhite ? lightlogo : darklogo}
                  alt="bnkle"
                  className="md:mr-12"
                  onClick={() => navigation("/")}
                />
                <div className=" hidden lg:flex items-center space-x-6 mt-5">
                  {links.map((m, i) => (
                    <span
                      onClick={() => {
                        if (i === 0 || i === 1) {
                          setDisplay(m);
                        }
                      }}
                      className={`${
                        isBgWhite ? "text-bblack-0" : "text-white"
                      } hidden lg:flex items-center`}
                      key={i}
                    >
                      {m}
                      {(i === 0 || i === 1) && (
                        <span className="ml-1">
                          {display === links[i] ? (
                            <FiChevronUp
                              size={16}
                              color={isBgWhite ? "black" : "white"}
                            />
                          ) : (
                            <FiChevronDown
                              size={16}
                              color={isBgWhite ? "black" : "white"}
                            />
                          )}
                        </span>
                      )}
                    </span>
                  ))}
                  {!removeReferral && (
                    <span
                      onClick={() => {
                        navigation("/referral");
                      }}
                      className={`${isBgWhite ? "text-bblack-0" : "text-white"}`}
                    >
                      Referral Program
                    </span>
                  )}
                </div>
              </div>

              <div className="lg:flex space-x-6 hidden items-center text-lg mt-5">
                {showLogIn === undefined || showLogIn === true ? (
                  <a
                    onClick={() => {
                      if (signInFn) {
                        signInFn();
                      }
                    }}
                    href={signInLink}
                    className={`${isBgWhite ? "text-bblack-0" : "text-white"}`}
                    rel="no-referer"
                  >
                    Login
                  </a>
                ) : null}
                <a
                  onClick={() => {
                    if (signUpFn) {
                      signUpFn();
                    }
                  }}
                  href={signUpLink}
                >
                  <button className="py-2 rounded-md bg-bblue px-8 text-white">
                    {signUpLabel ? signUpLabel : "Sign up"}
                  </button>
                </a>
              </div>
              <span className="z-30 lg:hidden">
                {!showMenu ? (
                  <AiOutlineMenu
                    size={16}
                    className="text-3xl ml-4 lg:hidden z-30"
                    onClick={toggleMenu}
                    color={isBgWhite ? "black" : "white"}
                  />
                ) : (
                  <AiOutlineClose
                    size={16}
                    className="text-xl ml-4 lg:hidden z-30"
                    onClick={toggleMenu}
                    color={"white"}
                  />
                )}
              </span>
            </div>
          </div>
          <span
            className=" flex flex-col mt-20 p-2 w-full sm:w-4/5 md:w-3/5 max-w-[373px]  mb-10  "
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
           

            {showLogIn === undefined || showLogIn === true ? (
              <a
                onClick={() => {
                  if (signInFn) {
                    signInFn();
                  }
                }}
                href={signInLink}
                className={` py-2 rounded-md bg-wgite my-3 bg-white  px-8 w-full border-bash border text-center text-white"`}
                rel="no-referer"
              >
                Login
              </a>
            ) : null}
            <a
              onClick={() => {
                if (signUpFn) {
                  signUpFn();
                }
              }}
              href={signUpLink}
            >
              <button className="py-2 rounded-md bg-bblue px-8 w-full text-center text-white">
                {signUpLabel ? signUpLabel : "Sign up"}
              </button>
            </a>
            {links.map((m, i) => (
              <span
                onClick={() => {
                  if (i === 0 || i === 1) {
                    if (display === m) {
                      setDisplay("");
                    } else {
                      setDisplay(m);
                    }
                  }
                }}
                className="flex flex-col w-full  "
              >
                <span className={`text-white flex items-center p-3  `} key={i}>
                  {m}
                  {(i === 0 || i === 1) && (
                    <span className="ml-1">
                      {display === links[i] ? (
                        <FiChevronUp size={16} color={"white"} />
                      ) : (
                        <FiChevronDown size={16} color={"white"} />
                      )}
                    </span>
                  )}
                </span>
                {display === links[i] &&
                  mysubs[display] &&
                  mysubs[display].map((m: any) => (
                    <a
                      className="text-white p-3"
                      href={m.link}
                      target={m.link.includes("http") ? "_blank" : ""}
                    >
                      {m.label}
                    </a>
                  ))}

                <div></div>
              </span>
            ))}
             {!removeReferral && (
              <span
                onClick={() => {
                  navigation("/referral");
                }}
                className={` text-white p-3 "`}
              >
                Referral Program
              </span>
            )}
          </span>
        </div>
      )}
      {display && (
        <>
          <div
            onClick={() => {
              setDisplay("");
            }}
            className=" w-screen hidden   bg-black  h-full   bg-opacity-50 lg:flex lg:flex-col jusitify-center z-[90]  absolute top-0  left-0"
          >
            <div className=" sticky top-0 ">
              {
                <div className="w-full">
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className="w-full z-[999] bg-bblack-0  "
                  >
                    <TopBar
                      display={display}
                      setDisplay={setDisplay}
                      className={className + " z-[999]"}
                      signInLink={signInLink}
                      signUpLink={signUpLink}
                      showMenu={showMenu}
                      toggleMenu={toggleMenu}
                      links={links}
                      signInFn={signInFn}
                      signUpFn={signUpFn}
                      removeReferral={removeReferral}
                    />
                  </div>
                </div>
              }
              <div className="w-full  ">
                <div className="w-[1234px] mx-auto">
                  <div className="bg-white rounded-lg h-fit w-fit cursor-pointer  p-10 ml-40  mt-2  ">
                    <p className="text-bash mb-8">{display}</p>
                    <div
                      className={`grid ${
                        mysubs[display] && mysubs[display].length > 2
                          ? "grid-cols-3"
                          : "grid-cols-2"
                      } gap-x-16  gap-y-4`}
                    >
                      {mysubs[display] &&
                        mysubs[display].map((m: any) => (
                          <a
                            href={m.link}
                            target={m.link.includes("http") ? "_blank" : ""}
                          >
                            <span className="flex hover:bg-lightblue rounded-md px-4 py-2 flex-col ">
                              <span className="flex  items-center mb-2 text-black ">
                                <span
                                  className={`rounded-md p-2 font-Bold ${
                                    m.iconbg ? m.iconbg : " bg-gray-50"
                                  } mr-2`}
                                >
                                  {React.createElement(m.icon, {
                                    size: 16,
                                    color: m.iconcolor,
                                  })}
                                </span>
                                {m.label}
                              </span>
                              <p className="text-bash max-w-[284px]">
                                {display === "Solutions"
                                  ? subcopies[m.link]
                                  : "Get end-to-end project procurement that optimzes speed and quality at the right price."}
                              </p>
                            </span>
                          </a>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
