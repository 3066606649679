import React, { useState } from "react";
import { questions } from "pages/landingpage/constants";
import { BsLinkedin, BsTwitter } from "react-icons/bs";
import couple from "assets/couple.png";
import threeguys from "assets/threeguys.svg";
import greybnkle from "assets/greybnkle.svg";
import bnklelogo from "assets/bnklelogo.svg";
import calculator from "assets/Calculator.svg";
import coins from "assets/coins.svg";
import { GrClose } from "react-icons/gr";
import Faq from "pages/landingpage/Faq";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toast";
import addresses from "constants/addresses";

const navTo = (str: string, mover: any) => {
  window.scrollTo({ top: 0 });
  mover(str);
};
export type data = {
  firstname: string;
  lastname: string;
  email: string;
  message: string;
};

var url = "https://sheet2api.com/v1/NDjDSZa8QwW4/bnklecrm/Sheet1";
let schema = Yup.object({
  firstname: Yup.string()
    .min(2, "firstname cannot be less than two characters")
    .required("please input firstname"),
  lastname: Yup.string()
    .min(2, "lastname cannot be less than two characters")
    .required("please input lastname"),
  email: Yup.string()
    .email("please enter a valid email")
    .required("please input email"),
  message: Yup.string()
    .min(7, "message cannot be less than seven characters")
    .required("please leave a message for us"),
});

export default function Fotter() {
  const [submitting, setSubmitting] = useState(false);
  const [intouch, setIntouch] = useState(false);

  let { pathname } = useLocation();
  let show = ["enterprise", "consultant", "contractor"].includes(
    pathname.split("/")[1]
  )
    ? false
    : true;
  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      message: "",
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      await submitForm(values);
    },
  });

  let navigate = useNavigate();
  const submitForm = async (data: data) => {
    setSubmitting(true);
    try {
      let res = await axios.post(url, {
        ...data,
        timestamp: new Date().toISOString(),
      });

      if (res.status < 300) {
        toast("Thank you for your message. We will reach out soon", {
          backgroundColor: "blue",
          color: "#fff",
        });
        formik.resetForm();
      } else {
        toast("We did not get your message. Please try again", {
          backgroundColor: "blue",
          color: "#fff",
        });
      }
    } catch (error) {
      toast("We did not get your message. Please try again", {
        backgroundColor: "blue",
        color: "#fff",
      });
    }
    setSubmitting(false);
  };

  return (
    <>
      <div className="bg-white p-5 w-full lg:px-0 md:pt-32 ">
        <div className=" text-bblack-0 text-center text-3xl md:text-4xl xl:text-4xl font-Bold mb-6">
          Frequently asked questions
        </div>
        <div className="text-center text-bash text-xl mb-16 ">
          Everything you need to know about the product and billing.
        </div>
        <div className="w-full">
          <div className="w-full max-w-[750px] mx-auto">
            {questions.map((m) => (
              <Faq question={m.question} answer={m.answer} />
            ))}
          </div>
        </div>
      </div>
      <div className="pt-16 pb-20 px-4 lg:px-36 bg-white">
        <div className="relative max-w-[1234px] bg-lightblue flex md:max-w-[750px] lg:max-w-max mx-auto flex-col items-center rounded-3xl md:py-16 px-3 md:px-48 lg:px-64 w-full py-4">
          {intouch && (
            <span
              onClick={() => setIntouch(false)}
              className="bg-white hover:cursor-pointer absolute top-8 right-8 p-2 rounded-full"
            >
              <GrClose size={16} />
            </span>
          )}
          <img src={threeguys} alt="three guys" />
          <p className="text-bblack-1 mt-5 text:xl sm:text-2xl md:text-4xl font-DemiBold text-center w-full ">
            Still have Questions?
          </p>
          <p className="mt-2 mb-8 text-base md:text-xl text-bash text-center">
            Can’t find the answers you’re looking for? Please send us a message
          </p>
          {intouch ? (
            <div className="m-auto max-w-[480px]">
              <div className="w-full flex ">
                <label
                  className="text-bblack-0 mt-2 mr-3 flex flex-col"
                  htmlFor="firstname"
                >
                  First name
                  <input
                    className="w-full py-3 px-4 mt-2  outline-bblue  rounded-md bg-white text-bash"
                    type="text"
                    name="firstname"
                    placeholder="First name"
                    value={formik.values.firstname}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.firstname && (
                    <p className="text-red text-sm text-red-500">
                      {formik.errors.firstname}
                    </p>
                  )}
                </label>
                <label
                  className="text-bblack-0 mb-6 mt-2 flex flex-col"
                  htmlFor="lastname"
                >
                  Last name
                  <input
                    className="w-full py-3 px-4 mt-2 outline-bblue  rounded-md bg-white text-bash"
                    type="text"
                    name="lastname"
                    placeholder="Last name"
                    value={formik.values.lastname}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.lastname && (
                    <p className="text-red text-sm text-red-500">
                      {formik.errors.lastname}
                    </p>
                  )}
                </label>
              </div>
              <label
                className="text-bblack-0 mb-6 mt-2 flex flex-col "
                htmlFor="email"
              >
                Email
                <input
                  className="w-full py-3 px-4  mt-2 outline-bblue  rounded-md bg-white text-bash"
                  type="text"
                  name="email"
                  placeholder="you@company.com"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
                {formik.errors.email && (
                  <p className="text-red text-sm text-red-500">
                    {formik.errors.email}
                  </p>
                )}
              </label>
              <label className="text-bblack-0  mb-6 mt-6" htmlFor="message">
                Message
                <textarea
                  className="w-full h-36 py-3 px-4 mt-2 outline-bblue  rounded-md bg-white text-bash"
                  name="message"
                  placeholder="Leave us a message..."
                  value={formik.values.message}
                  onChange={formik.handleChange}
                />
                {formik.errors.message && (
                  <p className="text-red text-sm text-red-500">
                    {formik.errors.message}
                  </p>
                )}
              </label>
              <button
                onClick={(e) => formik.handleSubmit()}
                className="bg-bblue text-white py-3 mt-6 flex justify-center  px-8 rounded-md w-full "
              >
                {submitting ? (
                  <div className="w-6 h-6 border-4  rounded-full border-t-white animate-spin border-x-slate-50 border-b-transparent   "></div>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          ) : (
            <button
              onClick={() => setIntouch(true)}
              className="bg-bblue text-white py-3 px-8 rounded-md "
            >
              Get in touch
            </button>
          )}
        </div>
      </div>
      {!!show && (
        <>
          <div className=" bg-ashShade-0">
            <div className="w-full mx-auto max-w-[1234px] px-5 md:px-10  py-10 md:py-28  flex  md:flex-row flex-col items-center justify-between">
              <div className="flex flex-col w-full md:w-[400px]  mr-0 sm:mr-3 font-DemiBold mb-5">
                <div className="flex flex-col items-center text-3xl  md:text-5xl md:items-start justify-center w-full">
                  <span className=" text-bblue text-center ">
                    Build your home
                  </span>
                  <span className=" md:text-left text-ashShade-10 md:ml-0 ml-2 text-center ">
                    with complete peace of mind
                  </span>
                </div>
                <span className="text-bash  text-lg md:text-xl max-w-xs md:max-w-max mx-auto text-center md:text-left md:mt-6 font-Medium">
                  Building a home has never been easier, everything you need to
                  deliver your expected outcome is at your fingertips.
                </span>
              </div>
              <img
                src={couple}
                alt=""
                className="w-10/12  md:w-1/2 self-center "
              />
            </div>
          </div>
        </>
      )}

      <div className=" bg-landingblue text-white w-full py-10 md:py-20 xl:py-32 px-5 md:px-36 xl:px-60 relative">
        <div className="mx-auto flex flex-col items-center text-center relative z-20">
          <p className="text-2xl md:text-4xl font-DemiBold">
            Get an idea of how much it will cost you
          </p>
          <p className="text-base md:text-xl mt-2 mb-8">
            Estimate the cost of building a home with our new and improved
            construction cost calculator
          </p>
          <button
            className="text-bblue bg-white py-3 px-8 rounded-md"
            onClick={() =>
              window.location.assign(
                `https://calculator.${process.env.REACT_APP_DOMAIN}`
              )
            }
          >
            Get Estimate
          </button>
        </div>
        <img
          src={calculator}
          className="absolute hidden md:block right-0 bottom-0"
          alt=""
        />
        <img
          src={coins}
          className="absolute hidden md:block left-0 bottom-0"
          alt=""
        />
      </div>

      <div className="bg-ashShade-0 w-full py-10 md:py-32   px-5 md:px-16 lg:px-36">
        <div className="w-full max-w-[1234px] m-auto">
          <div className=" border-b border-bash flex flex-col lg:flex-row items-start justify-between mb-6">
            <span className="md:mr-10 lg:mr-4 lg:w-[350px] xl:w-3/5 ">
              <img
                src={window.innerWidth > 700 ? greybnkle : bnklelogo}
                alt="bnkle"
                className="w-24 mb-6"
              />
              <span className=" ">
                bnkle's mission is to give clients, consultants, contractors and
                vendors the tools and resources to deliver construction projects
                hassle free.
              </span>
            </span>
            <div className=" mb-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-5 lg:gap-0 lg:grid-cols-3 md:grid-flow-col mt-10 md:w-full xl:w-fit xl:gap-x-10 mb-6  lg:mt-0">
                <span className="text-bblack-0 flex flex-col gap-y-3">
                  <p className="font-Medium uppercase text-bash">Links</p>
                  <Link to="#">Blogs</Link>
                  {/* <Link to="#">About Us</Link> */}
                  <Link to="#">Privacy</Link>
                </span>
                <span className="text-bblack-0 flex flex-col gap-y-3">
                  <p className="font-Medium uppercase text-bash whitespace-nowrap">
                    Products
                  </p>
                  <span
                    className=" hover:underline hover:cursor-pointer"
                    onClick={() => navTo("/contractor", navigate)}
                  >
                    For Contractor
                  </span>
                  <span
                    className=" hover:underline hover:cursor-pointer"
                    onClick={() => navTo("/consultant", navigate)}
                  >
                    For Consultant
                  </span>
                  <span
                    className=" hover:underline hover:cursor-pointer"
                    onClick={() => navTo("/owner", navigate)}
                  >
                    For Owner(B2C)
                  </span>
                  <span
                    className=" hover:underline hover:cursor-pointer"
                    onClick={() => navTo("/enterprise", navigate)}
                  >
                    For Owner(B2B)
                  </span>
                </span>
                <span className="text-bblack-0 flex flex-col gap-y-3">
                  <p className="font-Medium uppercase text-bash">contact</p>
                  <p className="whitespace-nowrap">+234 809 489 5688</p>
                  <p>hello@usebnkle.com</p>
                </span>
              </div>
              <div className="flex flex-wrap  gap-9 text-sm text-bash">
                {addresses.map((m) => (
                  <div>
                    <img className="w-4 h-4 mb-1" src={m.image} alt="" />
                    {m.text.split(",").map((_m, i) => (
                      <p>
                        {_m}
                        {i !== 2 ? "," : ""}
                      </p>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="text-left text-bash flex items-center justify-between">
            <span>© {new Date().getFullYear()} Bnkle. All rights reserved</span>
            <span className="flex items-center">
              <BsTwitter
                size={20}
                className="mr-3 cursor-pointer text-[#77828D] hover:text-[dodgerblue]"
                onClick={() =>
                  window.location.assign("//www.twitter.com/usebnkle")
                }
              />
              <BsLinkedin
                size={20}
                className="cursor-pointer text-[#77828D] hover:text-[#0C63BC]"
                onClick={() =>
                  window.location.assign("//www.linkedin.com/company/bnklé/")
                }
              />
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
