import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const Redirect = () => {
  let params = useParams();
  let appDomain = process.env.REACT_APP_DOMAIN;
  useEffect(() => {
    window.location.href = `https://auth.${appDomain}/referral/signup?referralCode=${params.refId}`;
  }, []);
  return (
    <div className="w-full h-[100vh] flex items-center justify-center">
      <div className=" flex flex-col items-center">
        <div className=" border-2 border-bblue animate-spin border-l-white  rounded-full   w-10 h-10  " />
        <p className="text-center mt-2">
          Redirecting
          <br />
          Please Wait...
        </p>
      </div>
    </div>
  );
};

export default Redirect;

