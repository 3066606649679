import { BsLinkedin, BsTwitter } from "react-icons/bs";
import bnklewhitelogo from "assets/svg/white-logo.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";

const navTo = (str: string, mover: any) => {
    window.scrollTo({ top: 0 });
    mover(str);
};

export default function Footer() {
    let navigate = useNavigate();

    return (
        <>
            <div className='bg-ashShade-10 text-bgSecondary w-full px-5 md:px-16 py-12'>
                <div className='w-full referral-container'>
                    <div className=' border-b border-white flex flex-col lg:flex-row items-start justify-between mb-6'>
                        <span className='md:w-10/12 lg:w-4/12 '>
                            <img
                                src={bnklewhitelogo}
                                alt='bnkle'
                                className='w-24 mb-6'
                            />
                            <span className='font-SatoshiRegular'>
                                bnkle's mission is to give clients, consultants,
                                contractors and vendors the tools and resources
                                to deliver construction projects hassle free.
                            </span>
                        </span>
                        <div className=' mb-6 md:w-6/12 lg:w-4/12'>
                            <div className='grid gap-5 md:grid-cols-2 md:gap-0 md:justify-between mt-10 md:w-full mb-6 lg:mt-0'>
                                <span className='flex flex-col gap-y-3 font-SatoshiRegular'>
                                    <p className='font-Medium uppercase  whitespace-nowrap'>
                                        Products
                                    </p>
                                    <span
                                        className=' hover:underline hover:cursor-pointer'
                                        onClick={() =>
                                            navTo("/contractor", navigate)
                                        }
                                    >
                                        For Contractor
                                    </span>
                                    <span
                                        className=' hover:underline hover:cursor-pointer'
                                        onClick={() =>
                                            navTo("/consultant", navigate)
                                        }
                                    >
                                        For Consultant
                                    </span>
                                    <span
                                        className=' hover:underline hover:cursor-pointer'
                                        onClick={() =>
                                            navTo("/owner", navigate)
                                        }
                                    >
                                        For Owner(B2C)
                                    </span>
                                    <span
                                        className=' hover:underline hover:cursor-pointer'
                                        onClick={() =>
                                            navTo("/enterprise", navigate)
                                        }
                                    >
                                        For Owner(B2B)
                                    </span>
                                </span>
                                <span className='flex flex-col gap-y-3 font-SatoshiRegular'>
                                    <p className='font-Medium uppercase'>
                                        Links
                                    </p>
                                    <a
                                        href={`https://calculator.${process.env.REACT_APP_DOMAIN}`}
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        Cost Calculator
                                    </a>
                                    <Link to='/contact'>Contact us</Link>
                                    <Link to='/referral'>Refer-to-earn</Link>
                                    <Link to='#'>Privacy</Link>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='text-left  flex flex-col gap-4 md:gap-0 md:flex-row md:items-center md:justify-between'>
                        <span>
                            © {new Date().getFullYear()} Bnkle. All rights
                            reserved
                        </span>
                        <span className='flex items-center'>
                            <BsTwitter
                                size={20}
                                className='mr-3 cursor-pointer text-bgSecondary hover:text-[dodgerblue]'
                                onClick={() =>
                                    window.location.assign(
                                        "//www.twitter.com/usebnkle"
                                    )
                                }
                            />
                            <BsLinkedin
                                size={20}
                                className='cursor-pointer text-bgSecondary hover:text-[#0C63BC]'
                                onClick={() =>
                                    window.location.assign(
                                        "//www.linkedin.com/company/bnklé/"
                                    )
                                }
                            />
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
}
